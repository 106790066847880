export const createRandomString = (length) => {
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   };
   return result;
};

export const getUploadFileSize = fileSize => {
   let kb = fileSize / 1024;
   if (kb < 1024) return `${kb.toFixed(2)} KB`;

   let mb = kb / 1024;
   if (mb < 1024) return `${mb.toFixed(2)} MB`;

   let gb = mb / 1024;
   return `${gb.toFixed(2)} GB`;
};

export const checkMail = (testMail) => {
   let regex = new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?")
   return regex.test(String(testMail).toLowerCase())
};

export const itemCountFormatter = (number) => {
   if(number > 999){
      let tmp = [];
      number = String(number);

      for (let i = 0; i<number.length; i++){
         tmp.push(number[i]);
      }
      for (let i = 0; i<3; i++){
         tmp.pop();
      }
      
      return tmp.join('') + 'k';
   }
   else{
      return number;
   }
}