import React from 'react';
import "./index.scss";
import Spinner from "../../components/Spinner";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { animateBox } from "../../modules/componentAnimation";
import offers from "../../offers";
import moment from 'moment/moment';

export default function Publishers() {
    const [spinner, setSpinner] = React.useState(true);
    const [data, setData] = React.useState([]);
    const [message, setMessage] = React.useState(null);

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/publishers/getAllPublishers`,
            data: {},
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data);
            } else {
                return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
            };
        }).catch(() => {
            return setMessage('Server ne reagira...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    React.useEffect(() => {
        getData();
    }, []);

    return (
        <div className='route__publishers'>
            <div className='route__publishers__header'>
                <p>Partneri</p>
                <div onClick={(e) => animateBox(e, <CreatePublisherModal getData={getData} />)} className='route__publishers__header__button'>
                    <p>NOVI PARTNER</p>
                </div>
            </div>

            <div className='route__publishers__table'>
                <div className='route__publishers__table__header'>
                    <strong>ID</strong>
                    <strong>Naziv</strong>
                    <strong>Tag</strong>
                    <strong>Ukupno upita</strong>
                    <strong>Potvrđenih upita</strong>
                    <strong>Datum kreiranja</strong>
                </div>
                {
                    !spinner && data?.data?.map((item, index) => {
                        return <div style={{ background: index % 2 === 0 ? 'rgb(71 88 119)' : 'rgb(94 110 141)' }} className='route__publishers__table__row'>
                            <p>{window.innerWidth < 601 && <p>ID: </p>}{item.Publisher.ID}</p>
                            <p>{window.innerWidth < 601 && <p>Naziv: </p>}{item.Publisher.Name}</p>
                            <p>{window.innerWidth < 601 && <p>Tag: </p>}{item.Publisher.Tag}</p>
                            <p>{window.innerWidth < 601 && <p>Donesenih upita: </p>}{item.Leads.length}</p>
                            <p>{window.innerWidth < 601 && <p>Potvrđenih upita: </p>}{item.Leads.filter(e => e.Status === 1).length}</p>
                            <p>{window.innerWidth < 601 && <p>Datum kreiranja: </p>}{moment(item.Publisher.createdAt).format('DD.MM.YYYY.')}</p>
                            <div
                                onClick={(e) => animateBox(e, <PublisherStats data={item?.Publisher} publisherID={item?.Publisher?.ID} publisherName={item?.Publisher?.Name} publisherTag={item?.Publisher?.Tag} />)}
                                className='route__publishers__table__row__button'>
                                <p>VIŠE</p>
                            </div>
                        </div>
                    })
                }
                {spinner && <Spinner style={{ width: '42px', marginLeft: 'auto', marginRight: 'auto', display: 'block'  }} color='#13213c' />}
                {message && <p style={{color: '#9b0b0b'}}>{message}</p>}
            </div>
        </div>
    )
}

const PublisherStats = (props) => {
    const [spinner, setSpinner] = React.useState(true);
    const [secondSpinner, setSecondSpinner] = React.useState(true);
    const [thirdSpinner, setThirdSpinner] = React.useState(true);
    const [fourthSpinner, setFourthSpinner] = React.useState(true);
    const [fifthSpinner, setFifthSpinner] = React.useState(true);
    const [data, setData] = React.useState([]);
    const [message, setMessage] = React.useState(null);
    const [monthData, setMonthData] = React.useState([]);
    const [todaysData, setTodaysData] = React.useState([]);
    const [yesterdayData, setYesterdayData] = React.useState([]);
    const [sevenData, setSevenData] = React.useState([]);
    const [trash, setTrash] = React.useState(true);

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/publishers/getAllPublishers`,
            data: {
                filters: [{ name: 'ID', op: 'eq', value: props.publisherID }]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data);
                setSpinner(false);
            } else {
                return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
            };
        }).catch(() => {
            return setMessage('Server ne reagira...');
        });
    }

    const getMonthData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/getMonthLeads`,
            data: {
                token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
                filters: [
                    { name: 'PartnerID', op: 'eq', value: props.data?.ID }
                ],
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setMonthData(res.data);
            } else {
                return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
            };
        }).catch(() => {
            return setMessage('Greška prilikom dohvatanja podataka...');
        }).finally(() => {
            setSecondSpinner(false);
        });
    }

    const getYesterdayData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/getYesterdayLeads`,
            data: {
                token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
                filters: [
                    { name: 'PartnerID', op: 'eq', value: props.data?.ID }
                ],
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setYesterdayData(res.data);
            } else {
                return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
            };
        }).catch(() => {
            return setMessage('Greška prilikom dohvatanja podataka...');
        }).finally(() => {
            setFifthSpinner(false);
        });
    }

    const getTodaysData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/getTodaysLeads`,
            data: {
                token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
                filters: [
                    { name: 'PartnerID', op: 'eq', value: props.data?.ID }
                ],
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setTodaysData(res.data);
            } else {
                return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
            };
        }).catch(() => {
            return setMessage('Greška prilikom dohvatanja podataka...');
        }).finally(() => {
            setThirdSpinner(false);
        });
    }

    const getSevenDaysData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/getSevenDaysLeads`,
            data: {
                token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
                filters: [
                    { name: 'PartnerID', op: 'eq', value: props.data?.ID }
                ],
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setSevenData(res.data);
            } else {
                return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
            };
        }).catch(() => {
            return setMessage('Greška prilikom dohvatanja podataka...');
        }).finally(() => {
            setFourthSpinner(false);
        });
    }

    const generateAPIdoc = async () => {
        try {
            const response = await fetch('/apiDocTemplate.txt');
            const templateText = await response.text();

            const filledText = templateText
                .replaceAll('{{ApiKey}}', props.data?.ApiKey)
                .replaceAll('{{Tag}}', props.data?.Tag)
                .replaceAll('{{PartnerID}}', props.data?.ID);

            const blob = new Blob([filledText], { type: 'text/plain' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${props.data?.Name} API documentation.txt`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Greška prilikom učitavanja ili generiranja dokumenta:', error);
        }
    };

    React.useEffect(() => {
        getData();
        getMonthData();
        getTodaysData();
        getSevenDaysData();
        getYesterdayData();
        window.scrollTo(0, 0);
    }, []);

    return <div className='route__publishers__statsModal'>
        <div className='route__publishers__statsModal__wrapper'>
            <div className='route__publishers__statsModal__wrapper__head'>
                <h1>{props.publisherName}</h1>
                <img onClick={props.onClose} src='images/head_close.svg' alt='' />
            </div>
            <div className='route__publishers__statsModal__wrapper__body'>
                {!spinner && !secondSpinner && !thirdSpinner && !fourthSpinner && !fifthSpinner && !message ? <div onClick={() => setTrash(n=> !n)} className='route__publishers__statsModal__wrapper__body__btn'><p>{trash ? 'Izbaci smeće' : 'Vrati smeće'}</p></div> : null}
                {!spinner && !secondSpinner && !thirdSpinner && !fourthSpinner && !fifthSpinner && !message ? <div className='route__publishers__statsModal__wrapper__body__leads'>
                    <div className='route__publishers__statsModal__wrapper__body__leads__head'>
                        <strong>Ukupno</strong>
                        <strong>Mjesec</strong>
                        <strong>7 dana</strong>
                        <strong>Jučer</strong>
                        <strong>Danas</strong>
                    </div>
                    {trash && <div className='route__publishers__statsModal__wrapper__body__leads__values'>
                        <p>{data?.data[0]?.Leads.length} / {data?.data[0]?.Leads.filter(e => e.Status === 1).length}</p>
                        <p>{monthData?.data?.length} / {monthData?.data?.filter(e => e.Status === 1).length}</p>
                        <p>{sevenData?.data?.length} / {sevenData?.data?.filter(e => e.Status === 1).length}</p>
                        <p>{yesterdayData?.data?.length} / {yesterdayData?.data?.filter(e => e.Status === 1).length}</p>
                        <p>{todaysData?.data?.length} / {todaysData?.data?.filter(e => e.Status === 1).length}</p>
                    </div>}
                    {!trash && <div className='route__publishers__statsModal__wrapper__body__leads__values'>
                        <p>{(data?.data[0]?.Leads).filter(obj => obj.Status !== 12).length} / {data?.data[0]?.Leads.filter(e => e.Status === 1).length}</p>
                        <p>{(monthData?.data).filter(obj =>  obj.Status !== 12)?.length} / {monthData?.data?.filter(e => e.Status === 1).length}</p>
                        <p>{(sevenData?.data).filter(obj => obj.Status !== 12)?.length} / {sevenData?.data?.filter(e => e.Status === 1).length}</p>
                        <p>{(yesterdayData?.data).filter(obj => obj.Status !== 12)?.length} / {yesterdayData?.data?.filter(e => e.Status === 1).length}</p>
                        <p>{(todaysData?.data).filter(obj => obj.Status !== 12)?.length} / {todaysData?.data?.filter(e => e.Status === 1).length}</p>
                    </div>}
                    <div className='route__publishers__statsModal__wrapper__body__leads__head'>
                        <strong>Ukupno</strong>
                        <strong>Mjesec</strong>
                        <strong>7 dana</strong>
                        <strong>Jučer</strong>
                        <strong>Danas</strong>
                    </div>
                    {trash && <div className='route__publishers__statsModal__wrapper__body__leads__values'>
                        <p>{(Number(data?.data[0]?.Leads.filter(e => e.Status === 1).length) / Number(data?.data[0]?.Leads.length) * 100).toFixed(0)}%</p>
                        <p>{(Number(monthData?.data?.filter(e => e.Status === 1).length) / Number(monthData?.data?.length) * 100).toFixed(0)}%</p>
                        <p>{(Number(sevenData?.data?.filter(e => e.Status === 1).length) / Number(sevenData?.data?.length) * 100).toFixed(0)}%</p>
                        <p>{(Number(yesterdayData?.data?.filter(e => e.Status === 1).length) / Number(yesterdayData?.data?.length) * 100).toFixed(0)}%</p>
                        <p>{(Number(todaysData?.data?.filter(e => e.Status === 1).length) / Number(todaysData?.data?.length) * 100).toFixed(0)}%</p>
                    </div>}
                    {!trash && <div className='route__publishers__statsModal__wrapper__body__leads__values'>
                        <p>{(Number(data?.data[0]?.Leads.filter(e => e.Status === 1).length) / Number((data?.data[0]?.Leads).filter(obj => obj.Status !== 12).length) * 100).toFixed(0)}%</p>
                        <p>{(Number(monthData?.data?.filter(e => e.Status === 1).length) / Number((monthData?.data).filter(obj => obj.Status !== 12)?.length) * 100).toFixed(0)}%</p>
                        <p>{(Number(sevenData?.data?.filter(e => e.Status === 1).length) / Number((sevenData?.data).filter(obj => obj.Status !== 12)?.length) * 100).toFixed(0)}%</p>
                        <p>{(Number(yesterdayData?.data?.filter(e => e.Status === 1).length) / Number((yesterdayData?.data).filter(obj => obj.Status !== 12)?.length) * 100).toFixed(0)}%</p>
                        <p>{(Number(todaysData?.data?.filter(e => e.Status === 1).length) / Number((todaysData?.data).filter(obj => obj.Status !== 12)?.length) * 100).toFixed(0)}%</p>
                    </div>}
                </div> : null}

                {!spinner && !secondSpinner && !thirdSpinner && !fourthSpinner && !message ? <div className='route__publishers__statsModal__wrapper__body__actions'>
                    <div
                        onClick={(e) => animateBox(e, <LeadsList publisherID={props.data?.ID} />)}
                        className='route__publishers__statsModal__wrapper__body__actions__button'>
                        <p>Izlistaj današnje upite</p>
                    </div>
                    <div
                        onClick={(e) => animateBox(e, <Payouts data={props.data?.Payouts} />)}
                        className='route__publishers__statsModal__wrapper__body__actions__button'>
                        <p>Pregled payout-a</p>
                    </div>
                    <div
                        onClick={(e) => animateBox(e, <BalanceModal publisherID={props.data?.ID} />)}
                        className='route__publishers__statsModal__wrapper__body__actions__button'>
                        <p>Balans</p>
                    </div>
                    <div
                        onClick={generateAPIdoc}
                        className='route__publishers__statsModal__wrapper__body__actions__button'>
                        <p>Dokumentacija</p>
                    </div>
                </div> : null}

                {spinner || secondSpinner || thirdSpinner || fourthSpinner ? <Spinner style={{ width: '36px' }} color='white' /> : null}
                {message && <p style={{ color: "red" }}>{message}</p>}
            </div>
        </div>
    </div>
}

const BalanceModal = (props) => {
    const [data, setData] = React.useState([]);
    const [spinner, setSpinner] = React.useState(true);
    const [btnSpinner, setBtnSpinner] = React.useState(false);
    const [message, setMessage] = React.useState(null);

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/publishers/getAllPublishers`,
            data: {
                filters: [{ name: 'ID', op: 'eq', value: props.publisherID }]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data);
            } else {
                return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
            };
        }).catch(() => {
            return setMessage('Server ne reagira...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const makePayout = (ID) => {
        setBtnSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/publishers/makePayout`,
            data: {
                ID: ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                getData();
            } else {
                return setMessage('Došlo je do greške prilikom slanja podataka...');
            };
        }).catch(() => {
            return setMessage('Server ne reagira...');
        }).finally(() => {
            setBtnSpinner(false);
        });
    }

    React.useEffect(() => {
        getData();
    }, []);

    return <div className='component__balanceModal'>
        <div className='component__balanceModal__wrapper'>
            <div className='component__balanceModal__wrapper__header'>
                <h1>Balans</h1>
                <img onClick={props.onClose} src='images/head_close.svg' alt='' />
            </div>
            {!spinner && !message ? <div className='component__balanceModal__wrapper__body'>
                <p>Trenutni balans: ${data?.data[0]?.Publisher?.Balance}</p>
                <div onClick={() => { makePayout(data?.data[0]?.Publisher?.ID) }} className='component__balanceModal__wrapper__body__button'>
                    {!btnSpinner && <p>Isplati</p>}
                    {btnSpinner && <Spinner color='rgb(19, 33, 60)' style={{ width: '22px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />}
                </div>
            </div> : null}
            {spinner && <Spinner color='white' style={{ width: '32px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />}
            {message && <p style={{ color: 'white', padding: '10px' }}>{message}</p>}
        </div>
    </div>
}

const Payouts = (props) => {
    const [data, setData] = React.useState([]);

    React.useEffect(() => {
        setData(JSON.parse(props.data))
    }, []);
    return <div className='component__payouts'>
        <div className='component__payouts__wrapper'>
            <div className='component__payouts__wrapper__header'>
                <h1>Payout-i</h1>
                <img onClick={props.onClose} src='images/head_close.svg' alt='' />
            </div>
            <div className='component__payouts__wrapper__body'>
                {data.length > 0 ? data?.map(elem => {
                    let tmp = offers.find(function (obj) {
                        return obj.ID === elem.offerID;
                    })
                    return <div className='component__payouts__wrapper__body__element'>
                        <p>{elem.offerID}</p>
                        <p>{tmp?.name}</p>
                        <p>{tmp?.country}</p>
                        <p>${elem.payout}</p>
                    </div>
                }) : <Spinner style={{ width: '30px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='white' />}
            </div>
        </div>
    </div>
}

const LeadsList = (props) => {
    const [data, setData] = React.useState([]);
    const [spinner, setSpinner] = React.useState(true);
    const [message, setMessage] = React.useState(null);
    const [country, setCountry] = React.useState(null);
    const [type, setType] = React.useState(null);

    const getData = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/getTodaysLeads`,
            data: {
                token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
                filters: [
                    { name: 'PartnerID', op: 'eq', value: props.publisherID },
                    country && { name: 'Country', op: 'eq', value: country },
                    type && { name: 'Type', op: 'eq', value: type }
                ],
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data);
            } else {
                return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
            };
        }).catch(() => {
            return setMessage('Greška prilikom dohvatanja podataka...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    React.useEffect(() => {
        window.scrollTo(0, 0);
        getData();
    }, [country, type]);

    return <div className='route__publishers__listLeadsModal'>
        <div className='route__publishers__listLeadsModal__wrapper'>
            <div className='route__publishers__listLeadsModal__wrapper__head'>
                <h1>Lista upita</h1>
                <img onClick={props.onClose} src='images/head_close.svg' alt='' />
            </div>
            <div className='route__publishers__listLeadsModal__wrapper__filters'>
                <div className='route__publishers__listLeadsModal__wrapper__filters__countries'>
                    <p style={{ background: country === 'BA' ? 'rgb(111, 119, 136)' : '', padding: '2px' }} onClick={() => setCountry('BA')}>BA</p>
                    <p style={{ background: country === 'RS' ? 'rgb(111, 119, 136)' : '', padding: '2px' }} onClick={() => setCountry('RS')}>RS</p>
                </div>
                <div className='route__publishers__listLeadsModal__wrapper__filters__type'>
                    <p style={{ background: type === 'LOW' ? 'rgb(111, 119, 136)' : '', padding: '2px' }} onClick={() => setType('LOW')}>LOW</p>
                    <p style={{ background: type === 'FULL' ? 'rgb(111, 119, 136)' : '', padding: '2px' }} onClick={() => setType('FULL')}>FULL</p>
                </div>
                <div className='route__publishers__listLeadsModal__wrapper__filters__buttons'>
                    <p onClick={() => { setCountry(null); setType(null) }}>PONIŠTI FILTERE</p>
                    <p onClick={(e) => animateBox(e, <ExportModal publisherID={props.publisherID} />)}>EXPORT</p>
                </div>
            </div>
            {!spinner && !message ? <div className='route__publishers__listLeadsModal__wrapper__body'>
                {
                    data?.data?.map(item => {
                        return <div className='route__publishers__listLeadsModal__wrapper__body__lead'>
                            <p>ID: #{item.ID}</p>
                            <p>Ime: {item.Name}</p>
                            <p>Broj: {item.Phone}</p>
                            <p>Proizvod: {item.Product}</p>
                            <p>Država: {item.Country}</p>
                            <p>Tip: {item.Type}</p>
                            <p>LeadHash: {item.LeadHash}</p>
                            <p className='route__publishers__listLeadsModal__wrapper__body__lead__status'>
                                Status:
                                {(() => {
                                    switch (item.Status) {
                                        case 0:
                                            return <p>Novi upiti</p>
                                            break;
                                        case 1:
                                            return <p>Potvrđeno</p>
                                            break;
                                        case 2:
                                            return <p>U isporuci</p>
                                            break;
                                        case 3:
                                            return <p>Naplaćeno</p>
                                            break;
                                        case 4:
                                            return <p>Odbijeno</p>
                                            break;
                                        case 5:
                                            return <p>Pozvati ponovo</p>
                                            break;
                                        case 6:
                                            return <p>Loš broj</p>
                                            break;
                                        case 7:
                                            return <p>Ne javlja se</p>
                                            break;
                                        case 9:
                                            return <p>Ne javlja se x4</p>
                                            break;
                                        case 10:
                                            return <p>Ne javlja se x6</p>
                                            break;
                                        case 11:
                                            return <p>Zadnja šansa</p>
                                            break;
                                        case 12:
                                            return <p>Smeće</p>
                                            break;
                                    }
                                })()}
                            </p>
                        </div>
                    })
                }
            </div> : null}
            {spinner && <Spinner style={{ width: '36px' }} color='white' />}
        </div>
    </div>
}

const ExportModal = (props) => {
    const [message, setMessage] = React.useState(null);
    const [spinner, setSpinner] = React.useState(false);

    const exportLeads = (type, period) => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/exportData`,
            data: {
                PartnerID: props.publisherID,
                Type: type,
                Period: period
            },
            responseType: 'blob',
            ...backendModule.axiosConfig
        }).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${period}-PartnerID-${props.publisherID}.xlsx`);
            document.body.appendChild(link);
            link.click();
        }).catch(() => {
            return setMessage('Greška prilikom dohvatanja podataka...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    return <div className='component__exportModal'>
        <div className='component__exportModal__wrapper'>
            <div className='component__exportModal__wrapper__header'>
                <h1>Export</h1>
                <img onClick={props.onClose} src='images/head_close.svg' alt='' />
            </div>
            {!spinner && !message ? <div className='component__exportModal__wrapper__body'>
                <div onClick={() => exportLeads('LOW', 'today')} className='component__exportModal__wrapper__body__button'>
                    <p>EXPORT DANAS LOW</p>
                </div>
                <div onClick={() => exportLeads('LOW', 'yesterday')} className='component__exportModal__wrapper__body__button'>
                    <p>EXPORT JUČER LOW</p>
                </div>
                <div onClick={() => exportLeads('LOW', 'sevenDays')} className='component__exportModal__wrapper__body__button'>
                    <p>EXPORT 7 DANA LOW</p>
                </div>
                <div onClick={() => exportLeads('LOW', 'lastMonth')} className='component__exportModal__wrapper__body__button'>
                    <p>EXPORT MJESEC LOW</p>
                </div>
                <div onClick={() => exportLeads('FULL', 'today')} className='component__exportModal__wrapper__body__button'>
                    <p>EXPORT DANAS FULL</p>
                </div>
                <div onClick={() => exportLeads('FULL', 'yesterday')} className='component__exportModal__wrapper__body__button'>
                    <p>EXPORT JUČER FULL</p>
                </div>
                <div onClick={() => exportLeads('FULL', 'sevenDays')} className='component__exportModal__wrapper__body__button'>
                    <p>EXPORT 7 DANA FULL</p>
                </div>
                <div onClick={() => exportLeads('FULL', 'lastMonth')} className='component__exportModal__wrapper__body__button'>
                    <p>EXPORT MJESEC FULL</p>
                </div>
            </div> : null}
            {spinner && <Spinner style={{width: '34px', marginLeft: 'auto', marginRight: 'auto', display: 'block'}} />}
            {message && <p style={{color: 'white'}}>{message}</p>}
        </div>
    </div>
}

const CreatePublisherModal = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [message, setMessage] = React.useState(null);
    const [payouts, setPayouts] = React.useState([]);

    const nameRef = React.useRef(null);
    const tagRef = React.useRef(null);


    const createPublisher = () => {
        if (!nameRef.current.value || !tagRef.current.value) return setMessage('Popunite sva polja');

        setSpinner(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/publishers/addPublisher`,
            data: {
                Name: nameRef.current.value,
                Tag: tagRef.current.value,
                Payouts: payouts
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.getData();
                props.onClose();
            } else {
                return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
            };
        }).catch((e) => {
            console.log(e);
            return setMessage('Server ne reagira...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const handlePayouts = (offerID, value) => {
        let thatOffer = payouts.find(function (obj) {
            return obj.offerID === offerID;
        });
        if (thatOffer) {
            payouts.map(item => {
                if (item.offerID === offerID) {
                    item.payout = value;
                }
            })
        }
        else {
            setPayouts(prev => [...prev, { offerID: offerID, payout: value }]);
        }
    }

    return <div className='component__createModal'>
        <div className='component__createModal__wrapper'>
            <div className='component__createModal__wrapper__header'>
                <h1>Novi partner</h1>
                <img onClick={props.onClose} src='images/head_close.svg' alt='' />
            </div>
            <div className='component__createModal__wrapper__body'>
                <input ref={nameRef} type='text' placeholder='Naziv' />
                <input ref={tagRef} type='text' placeholder='Tag' />
                <div className='component__createModal__wrapper__body__payouts'>
                    {
                        offers.map(offer => {
                            return <div className='component__createModal__wrapper__body__payouts__field'>
                                <label>{offer.ID} - {offer.name} - {offer.country}</label>
                                <input onChange={(e) => handlePayouts(offer.ID, e.currentTarget.value)} type='number' />
                            </div>
                        })
                    }
                </div>
                <div onClick={() => createPublisher()} className='component__createModal__wrapper__body__button'>
                    {!spinner && <p>SAČUVAJ</p>}
                    {spinner && <Spinner color='white' style={{ width: '24px' }} />}
                </div>
                <p className='component__createModal__wrapper__body__message'>{message}</p>
            </div>
        </div>
    </div>
}