import React from 'react';
import "./index.scss";

import Spinner from '../Spinner';

import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import moment from 'moment';

export default function Finances(props) {
    const incomeRef = React.useRef(null);
    const expenseRef = React.useRef(null);
    const descriptionRef = React.useRef(null);
    const incomeRsRef = React.useRef(null);
    const expenseRsRef = React.useRef(null);
    const descriptionRsRef = React.useRef(null);
    const rsDanijelaRef = React.useRef(null);
    const [spinner, setSpinner] = React.useState(false);
    const [initSpinner, setInitSpinner] = React.useState(true);
    const [dSpinner, setDspinner] = React.useState(false);
    const [message, setMessage] = React.useState(null);
    const [data, setData] = React.useState([]);
    const [total, setTotal] = React.useState(0);
    const [totalRS, setTotalRS] = React.useState(0);
    const [successDanijelaMsg, setSuccessDanijelaMsg] = React.useState(null);

    const getData = () => {
        setTotal(0);
        setTotalRS(0);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/finances/getAllrecords`,
            data: {},
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data);
                res.data?.data?.map((item, index) => {
                    if(item.Type === 'income' && item.Country === 'BA'){
                        setTotal(prev => prev + item.Amount);
                    }
                    else if(item.Type === 'expense' && item.Country === 'BA'){
                        setTotal(prev => prev - item.Amount);
                    }
                    else if(item.Type === 'income' && item.Country === 'RS'){
                        setTotalRS(prev => prev + item.Amount);
                    }
                    else if(item.Type === 'expense' && item.Country === 'RS'){
                        setTotalRS(prev => prev - item.Amount);
                    }
                });
            } else {
                return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
            };
        }).catch(() => {
            return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
        }).finally(() => {
            setInitSpinner(false);
        });
    }

    const addRecord = (country) => {
        let type = null;
        let amount = null;
        let description = null;

        switch (country) {
            case 'BA':
                description = descriptionRef.current.value;
                if (incomeRef.current.value > 0 && expenseRef.current.value > 0) {
                    setMessage('Ne možete dodati i rashod i prihod istovremeno!');
                    return setTimeout(() => {
                        setMessage(null);
                    }, 3500);
                }
                if (!incomeRef.current.value && !expenseRef.current.value) {
                    setMessage('Popunite polja');
                    return setTimeout(() => {
                        setMessage(null);
                    }, 3500);
                }
                if (incomeRef.current.value > 0) {
                    type = 'income';
                    amount = incomeRef.current.value;
                }
                else if (expenseRef.current.value > 0) {
                    type = 'expense';
                    amount = expenseRef.current.value;
                }
                break;
            case 'RS':
                description = descriptionRsRef.current.value;
                if (incomeRsRef.current.value > 0 && expenseRsRef.current.value > 0) {
                    setMessage('Ne možete dodati i rashod i prihod istovremeno!');
                    return setTimeout(() => {
                        setMessage(null);
                    }, 3500);
                }
                if (!incomeRsRef.current.value && !expenseRsRef.current.value) {
                    setMessage('Popunite polja');
                    return setTimeout(() => {
                        setMessage(null);
                    }, 3500);
                }
                if (incomeRsRef.current.value > 0) {
                    type = 'income';
                    amount = incomeRsRef.current.value;
                }
                else if (expenseRsRef.current.value > 0) {
                    type = 'expense';
                    amount = expenseRsRef.current.value;
                }
                break;
        }

        setSpinner(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/finances/addRecord`,
            data: {
                Type: type,
                Country: country,
                Amount: amount,
                Description: description
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                getData();
                incomeRef.current.value = '';
                incomeRsRef.current.value = '';
                expenseRef.current.value = '';
                expenseRsRef.current.value = '';
                descriptionRef.current.value = '';
                descriptionRsRef.current.value = '';
            } else {
                return setMessage('Došlo je do greške prilikom slanja podataka...');
            };
        }).catch(() => {
            return setMessage('Došlo je do greške prilikom slanja podataka...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const addCashToRS = () => {
        setDspinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/finances/addRecord`,
            data: {
                Type: 'income',
                Country: 'RS',
                Amount: rsDanijelaRef.current.value,
                Description: 'Naplata od pošte'
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                rsDanijelaRef.current.value = '';
                setSuccessDanijelaMsg('Uspjela si ej!');
            } else {
                return setSuccessDanijelaMsg('Došlo je do greške prilikom slanja podataka...');
            };
        }).catch(() => {
            return setSuccessDanijelaMsg('Došlo je do greške prilikom slanja podataka...');
        }).finally(() => {
            setDspinner(false);
        });
    }

    React.useEffect(() => {
        getData();
    }, []);

    return (
        <div className='route__finances'>
            <div className='route__finances__header'>
                <p>Finansije</p>
            </div>

            {!initSpinner && !props.isPostRS && <div className='route__finances__wrapper'>
                <div className='route__finances__wrapper__bosnia'>
                    <span>
                        <strong>BOSNA I HERCEGOVINA</strong>
                        <strong>UKUPNO: {total.toLocaleString('de-DE')} KM</strong>
                    </span>
                    <div className='route__finances__wrapper__bosnia__table'>
                        {
                            data?.data?.filter((item) => item.Country === 'BA').map((item, index) => {
                                if (item.Country === 'BA') {
                                    return <div
                                        style={{ background: index % 2 === 0 ? 'rgb(52 67 95)' : 'rgba(135, 147, 171, 0.48)', borderLeft: item.Type === 'income' ? '7px solid #009900' : '7px solid #ff4c4c'}}
                                        className='route__finances__wrapper__bosnia__table__row'
                                    >
                                        <p>{moment(item.createdAt).format('DD.MM.YYYY.')}</p>
                                        <p>{item.Type === 'income' ? 'PRIHOD' : 'RASHOD'}</p>
                                        <p>{item.Description}</p>
                                        <p className='route__finances__wrapper__bosnia__table__row__value'>{item.Amount} KM</p>
                                    </div>
                                }
                            })
                        }
                    </div>
                    {!message && <div className='route__finances__wrapper__bosnia__inputs'>
                        <input ref={incomeRef} type='number' placeholder='Prihod' />
                        <input ref={expenseRef} type='number' placeholder='Rashod' />
                        <input ref={descriptionRef} type='text' placeholder='Opis' />
                        {!spinner && <button onClick={() => addRecord('BA')}>UNESI</button>}
                        {spinner && <Spinner style={{width: '24px', height: '24px'}} align='center' color='#13213C' />}
                    </div>}
                    {message && <p style={{ fontSize: '18px', color: '#ff4c4c', marginLeft: '20px' }}>{message}</p>}
                </div>
                <div className='route__finances__wrapper__serbia'>
                    <span>
                        <strong>SRBIJA</strong>
                        <strong>UKUPNO: {totalRS.toLocaleString('de-DE')} RSD</strong>
                    </span>
                    <div className='route__finances__wrapper__serbia__table'>
                    {
                            data?.data?.filter((item) => item.Country === 'RS').map((item, index) => {
                                if (item.Country === 'RS') {
                                    return <div
                                        style={{ background: index % 2 === 0 ? 'rgb(52 67 95)' : 'rgba(135, 147, 171, 0.48)', borderLeft: item.Type === 'income' ? '7px solid #009900' : '7px solid #ff4c4c'}}
                                        className='route__finances__wrapper__bosnia__table__row'
                                    >
                                        <p>{moment(item.createdAt).format('DD.MM.YYYY.')}</p>
                                        <p>{item.Type === 'income' ? 'PRIHOD' : 'RASHOD'}</p>
                                        <p>{item.Description}</p>
                                        <p className='route__finances__wrapper__bosnia__table__row__value'>{item.Amount} RSD</p>
                                    </div>
                                }
                            })
                        }
                    </div>
                    {!message && <div className='route__finances__wrapper__serbia__inputs'>
                        <input ref={incomeRsRef} type='number' placeholder='Prihod' />
                        <input ref={expenseRsRef} type='number' placeholder='Rashod' />
                        <input ref={descriptionRsRef} type='text' placeholder='Opis' />
                        {!spinner && <button onClick={() => addRecord('RS')}>UNESI</button>}
                        {spinner && <Spinner style={{width: '24px', height: '24px'}} align='center' color='#13213C' />}
                    </div>}
                    {message && <p style={{ fontSize: '18px', color: '#ff4c4c', marginLeft: '20px' }}>{message}</p>}
                </div>
            </div>}
            <br></br>
            {initSpinner && <Spinner color='rgb(19, 33, 60)' width='32px' align='center' />}
            {props.isPostRS && <div className='route__finances__danijela'>
                <p>Đes Danijela, baci keš dole ispod :D</p>
                <input ref={rsDanijelaRef} type='number' />
                {!dSpinner && <button onClick={() => addCashToRS()}>BACILA</button>}
                {dSpinner && <Spinner style={{width: '24px', height: '24px'}} align='center' color='rgb(19, 33, 60)' />}
                {successDanijelaMsg && <p>{successDanijelaMsg}</p>}
            </div>}
        </div>
    )
}
