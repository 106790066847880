import { combineReducers } from "redux";

import timestampReducer from "./timestampReducer";
import userDataReducer from "./userDataReducer";
import typesReducer from "./typesReducer";
import siteDataReducer from "./siteDataReducer";

import chatUsersReducer from "./chatUsersReducer";
import chatRoomsReducer from "./chatRoomsReducer";
import chatReducer from "./chatReducer";

export default combineReducers({
    siteData: siteDataReducer,
    timestamp: timestampReducer,
    userData: userDataReducer,
    types: typesReducer,

    chatUsers: chatUsersReducer,
    chatRooms: chatRoomsReducer,
    chats: chatReducer,
});