const offers = [
    {ID: 1, name: 'ProstaMed', country: 'BA', scriptPath: '/agentScripts/prostamed.pdf'}, 
    {ID: 2, name: 'RegulMed', country: 'BA', scriptPath: '/agentScripts/regulmed.pdf'}, 
    {ID: 3, name: 'Hemol', country: 'BA', scriptPath: '/agentScripts/hemol.pdf'}, 
    {ID: 4, name: 'K-MaxMed', country: 'BA', scriptPath: '/agentScripts/kmaxmed.pdf'}, 
    {ID: 5, name: 'VisionMed', country: 'BA', scriptPath: '/agentScripts/visionmed.pdf'}, 
    {ID: 6, name: 'FlexoMed', country: 'BA', scriptPath: '/agentScripts/flexomed.pdf'}, 
    {ID: 7, name: 'ResundMed', country: 'BA', scriptPath: '/agentScripts/resundmed.pdf'}, 
    {ID: 8, name: 'SlimMed', country: 'BA', scriptPath: '/agentScripts/slimmed.pdf'}, 
    {ID: 9, name: 'ProstaMed', country: 'RS', scriptPath: '/agentScripts/prostamed.pdf'},
    {ID: 10, name: 'RegulMed', country: 'RS', scriptPath: '/agentScripts/regulmed.pdf'},
    {ID: 11, name: 'Hemol', country: 'RS', scriptPath: '/agentScripts/hemol.pdf'},
    {ID: 12, name: 'K-MaxMed', country: 'RS', scriptPath: '/agentScripts/kmaxmed.pdf'}, 
    {ID: 13, name: 'VisionMed', country: 'RS', scriptPath: '/agentScripts/visionmed.pdf'}, 
    {ID: 14, name: 'FlexoMed', country: 'RS', scriptPath: '/agentScripts/flexomed.pdf'},
    {ID: 15, name: 'ResundMed', country: 'RS', scriptPath: '/agentScripts/resundmed.pdf'},
    {ID: 16, name: 'SlimMed', country: 'RS', scriptPath: '/agentScripts/slimmed.pdf'},
    {ID: 17, name: 'LOW ProstaMed', country: 'BA', scriptPath: '/agentScripts/prostamed.pdf'},
    {ID: 18, name: 'LOW RegulMed', country: 'BA', scriptPath: '/agentScripts/regulmed.pdf'},
    {ID: 19, name: 'LOW Hemol', country: 'BA', scriptPath: '/agentScripts/hemol.pdf'},
    {ID: 20, name: 'LOW K-MaxMed', country: 'BA', scriptPath: '/agentScripts/kmaxmed.pdf'},
    {ID: 21, name: 'LOW VisionMed', country: 'BA', scriptPath: '/agentScripts/visionmed.pdf'},
    {ID: 22, name: 'LOW FlexoMed', country: 'BA', scriptPath: '/agentScripts/flexomed.pdf'},
    {ID: 23, name: 'LOW ResundMed', country: 'BA', scriptPath: '/agentScripts/resundmed.pdf'},
    {ID: 24, name: 'LOW SlimMed', country: 'BA', scriptPath: '/agentScripts/slimmed.pdf'},
    {ID: 25, name: 'LOW ProstaMed', country: 'RS', scriptPath: '/agentScripts/prostamed.pdf'},
    {ID: 26, name: 'LOW RegulMed', country: 'RS', scriptPath: '/agentScripts/regulmed.pdf'},
    {ID: 27, name: 'LOW Hemol', country: 'RS', scriptPath: '/agentScripts/hemol.pdf'},
    {ID: 28, name: 'LOW K-MaxMed', country: 'RS', scriptPath: '/agentScripts/kmaxmed.pdf'},
    {ID: 29, name: 'LOW VisionMed', country: 'RS', scriptPath: '/agentScripts/visionmed.pdf'},
    {ID: 30, name: 'LOW FlexoMed', country: 'RS', scriptPath: '/agentScripts/flexomed.pdf'},
    {ID: 31, name: 'LOW ResundMed', country: 'RS', scriptPath: '/agentScripts/resundmed.pdf'},
    {ID: 32, name: 'LOW SlimMed', country: 'RS', scriptPath: '/agentScripts/slimmed.pdf'},
    {ID: 33, name: 'LOW CardoMed', country: 'RS', scriptPath: '/agentScripts/cardomed.pdf'},
    {ID: 34, name: 'CardoMed', country: 'RS', scriptPath: '/agentScripts/cardomed.pdf'},
    {ID: 35, name: 'LOW CardoMed', country: 'BA', scriptPath: '/agentScripts/cardomed.pdf'},
    {ID: 36, name: 'CardoMed', country: 'BA', scriptPath: '/agentScripts/cardomed.pdf'},
    {ID: 40, name: 'ProstaMed', country: 'HR', scriptPath: '/agentScripts/prostamed.pdf'},
    {ID: 41, name: 'Hemol', country: 'HR', scriptPath: '/agentScripts/hemol.pdf'},
    {ID: 42, name: 'RegulMed', country: 'HR', scriptPath: '/agentScripts/regulmed.pdf'},
    {ID: 43, name: 'VisionMed', country: 'HR', scriptPath: '/agentScripts/visionmed.pdf'},
    {ID: 44, name: 'K-MaxMed', country: 'HR', scriptPath: '/agentScripts/kmaxmed.pdf'},
    {ID: 45, name: 'FlexoMed', country: 'HR', scriptPath: '/agentScripts/flexomed.pdf'},
    {ID: 46, name: 'SlimMed', country: 'HR', scriptPath: '/agentScripts/slimmed.pdf'},
    {ID: 47, name: 'CardoMed', country: 'HR', scriptPath: '/agentScripts/cardomed.pdf'},
    {ID: 48, name: 'ResunMed', country: 'HR', scriptPath: '/agentScripts/resunmed.pdf'},
    {ID: 49, name: 'LOW ProstaMed', country: 'HR', scriptPath: '/agentScripts/prostamed.pdf'},
    {ID: 50, name: 'LOW Hemol', country: 'HR', scriptPath: '/agentScripts/hemol.pdf'},
    {ID: 51, name: 'LOW RegulMed', country: 'HR', scriptPath: '/agentScripts/regulmed.pdf'},
    {ID: 52, name: 'LOW VisionMed', country: 'HR', scriptPath: '/agentScripts/visionmed.pdf'},
    {ID: 53, name: 'LOW K-MaxMed', country: 'HR', scriptPath: '/agentScripts/kmaxmed.pdf'},
    {ID: 55, name: 'LOW FlexoMed', country: 'HR', scriptPath: '/agentScripts/flexomed.pdf'},
    {ID: 56, name: 'LOW SlimMed', country: 'HR', scriptPath: '/agentScripts/slimmed.pdf'},
    {ID: 57, name: 'LOW CardoMed', country: 'HR', scriptPath: '/agentScripts/cardomed.pdf'},
    {ID: 58, name: 'LOW ResunMed', country: 'HR', scriptPath: '/agentScripts/resunmed.pdf'},
    {ID: 59, name: 'ProstaMed', country: 'MK', scriptPath: '/agentScripts/prostamed.pdf'},
    {ID: 60, name: 'Hemol', country: 'MK', scriptPath: '/agentScripts/hemol.pdf'},
    {ID: 61, name: 'RegulMed', country: 'MK', scriptPath: '/agentScripts/regulmed.pdf'},
    {ID: 62, name: 'VisionMed', country: 'MK', scriptPath: '/agentScripts/visionmed.pdf'},
    {ID: 63, name: 'K-MaxMed', country: 'MK', scriptPath: '/agentScripts/kmaxmed.pdf'},
    {ID: 64, name: 'FlexoMed', country: 'MK', scriptPath: '/agentScripts/flexomed.pdf'},
    {ID: 65, name: 'SlimMed', country: 'MK', scriptPath: '/agentScripts/slimmed.pdf'},
    {ID: 66, name: 'CardoMed', country: 'MK', scriptPath: '/agentScripts/cardomed.pdf'},
    {ID: 67, name: 'ResunMed', country: 'MK', scriptPath: '/agentScripts/resunmed.pdf'},
    {ID: 68, name: 'LOW ProstaMed', country: 'MK', scriptPath: '/agentScripts/prostamed.pdf'},
    {ID: 69, name: 'LOW Hemol', country: 'MK', scriptPath: '/agentScripts/hemol.pdf'},
    {ID: 70, name: 'LOW RegulMed', country: 'MK', scriptPath: '/agentScripts/regulmed.pdf'},
    {ID: 71, name: 'LOW VisionMed', country: 'MK', scriptPath: '/agentScripts/visionmed.pdf'},
    {ID: 72, name: 'LOW K-MaxMed', country: 'MK', scriptPath: '/agentScripts/kmaxmed.pdf'},
    {ID: 73, name: 'LOW FlexoMed', country: 'MK', scriptPath: '/agentScripts/flexomed.pdf'},
    {ID: 74, name: 'LOW SlimMed', country: 'MK', scriptPath: '/agentScripts/slimmed.pdf'},
    {ID: 75, name: 'LOW CardoMed', country: 'MK', scriptPath: '/agentScripts/cardomed.pdf'},
    {ID: 76, name: 'LOW ResunMed', country: 'MK', scriptPath: '/agentScripts/resunmed.pdf'},
    {ID: 77, name: 'LOW ParazitMed', country: 'HR', scriptPath: '/agentScripts/parazitmed.pdf'},
    {ID: 78, name: 'LOW ParazitMed', country: 'BA', scriptPath: '/agentScripts/parazitmed.pdf'},
    {ID: 79, name: 'LOW ParazitMed', country: 'RS', scriptPath: '/agentScripts/parazitmed.pdf'},
    {ID: 80, name: 'ParazitMed', country: 'HR', scriptPath: '/agentScripts/parazitmed.pdf'},
    {ID: 81, name: 'ParazitMed', country: 'BA', scriptPath: '/agentScripts/parazitmed.pdf'},
    {ID: 82, name: 'ParazitMed', country: 'RS', scriptPath: '/agentScripts/parazitmed.pdf'},
    {ID: 83, name: 'LOW CystMed', country: 'HR', scriptPath: '/agentScripts/cystmed.pdf'},
    {ID: 84, name: 'LOW CystMed', country: 'BA', scriptPath: '/agentScripts/cystmed.pdf'},
    {ID: 85, name: 'LOW CystMed', country: 'RS', scriptPath: '/agentScripts/cystmed.pdf'},
    {ID: 86, name: 'CystMed', country: 'HR', scriptPath: '/agentScripts/cystmed.pdf'},
    {ID: 87, name: 'CystMed', country: 'BA', scriptPath: '/agentScripts/cystmed.pdf'},
    {ID: 88, name: 'CystMed', country: 'RS', scriptPath: '/agentScripts/cystmed.pdf'},
];

export default offers;