export const flagColors = flg => {
    switch (flg) {
        case "isAdmin": return {
            backgroundColor: "#EFF4FF",
            color: "#8099D9",
            border: "1px solid #8099D9"
        };
        case "isManagement": return {
            backgroundColor: "#E1F7EB",
            color: "#48BB78",
            border: "1px solid #48BB78"
        };
        case "isSalesAdvisor": return {
            backgroundColor: "#FFEFFB",
            color: "#D783E4",
            border: "1px solid #D783E4"
        };
        case "isUnderwriter": return {
            backgroundColor: "#FFF5E5",
            color: "#FDCE30",
            border: "1px solid #FDCE30"
        };
        case "isCustomerCareAdvisor": return {
            backgroundColor: "#F2FFEF",
            color: "#6ECD6C",
            border: "1px solid #6ECD6C"
        };
        case "isMarketing": return {
            backgroundColor: "#FFFBEF",
            color: "#F3AF71",
            border: "1px solid #F3AF71"
        };
        case "isInsurer": return {
            backgroundColor: "#EFFFFE",
            color: "#65C3C9",
            border: "1px solid #65C3C9"
        };
        case "isSalesExecutive": return {
            backgroundColor: "#EFF5FF",
            color: "#459AD8",
            border: "1px solid #459AD8"
        };
        default: return {
            backgroundColor: "#F1E9FF",
            color: "#A180D9",
            border: "1px solid #A180D9"
        };
    };
};

export const flagNameColors = flg => {
    switch (flg) {
        case "Admin": return {
            backgroundColor: "#EFF4FF",
            color: "#8099D9",
            border: "1px solid #8099D9"
        };
        case "Management": return {
            backgroundColor: "#E1F7EB",
            color: "#48BB78",
            border: "1px solid #48BB78"
        };
        case "Sales advisor": return {
            backgroundColor: "#FFEFFB",
            color: "#D783E4",
            border: "1px solid #D783E4"
        };
        case "Underwriter": return {
            backgroundColor: "#FFF5E5",
            color: "#FDCE30",
            border: "1px solid #FDCE30"
        };
        case "Customer care advisor": return {
            backgroundColor: "#F2FFEF",
            color: "#6ECD6C",
            border: "1px solid #6ECD6C"
        };
        case "Marketing": return {
            backgroundColor: "#FFFBEF",
            color: "#F3AF71",
            border: "1px solid #F3AF71"
        };
        case "Insurer": return {
            backgroundColor: "#EFFFFE",
            color: "#65C3C9",
            border: "1px solid #65C3C9"
        };
        case "Sales executive": return {
            backgroundColor: "#EFF5FF",
            color: "#459AD8",
            border: "1px solid #459AD8"
        };
        default: return {
            backgroundColor: "#F1E9FF",
            color: "#A180D9",
            border: "1px solid #A180D9"
        };
    };
};