import React, { useState } from 'react';
import "./index.css";
import Spinner from "../../components/Spinner";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { animateBox } from "../../modules/componentAnimation";
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import offers from "../../offers";
import moment from 'moment/moment';

export default function Kanban(props) {
  const [spinner, setSpinner] = React.useState(true);
  const [secSpinner, setSecSpinner] = React.useState(false);
  const [searchResult, setSearchResult] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [dataNew, setDataNew] = React.useState({ status: null, data: [] });
  const [dataConf, setDataConf] = React.useState({ status: null, data: [] });
  const [dataPjk, setDataPjk] = React.useState({ status: null, data: [] });
  const [dataDelivery, setDataDelivery] = React.useState({ status: null, data: [] });
  const [dataPaid, setDataPaid] = React.useState({ status: null, data: [] });
  const [dataReject, setDataReject] = React.useState({ status: null, data: [] });
  const [dataCall, setDataCall] = React.useState({ status: null, data: [] });
  const [dataWrong, setDataWrong] = React.useState({ status: null, data: [] });
  const [dataNa, setDataNa] = React.useState({ status: null, data: [] });
  const [dataNa4, setDataNa4] = React.useState({ status: null, data: [] });
  const [dataNa6, setDataNa6] = React.useState({ status: null, data: [] });
  const [dataLastC, setDataLastC] = React.useState({ status: null, data: [] });
  const [dataTrash, setDataTrash] = React.useState({ status: null, data: [] });
  const [message, setMessage] = React.useState(null);
  const [selectedFilter, setSelectedFilter] = React.useState({ name: 'Sve', value: -1 });
  const [dropdown, setDropdown] = React.useState(false);
  const [selectedCountryFilter, setSelectedCountryFilter] = React.useState({ name: 'Sve', value: null });
  const [countryDropdown, setCountryDropdown] = React.useState(false);
  const [statusList, setStatusList] = React.useState({ LeadID: 'NaN', opened: false });
  const [paginationSpinner, setPaginationSpinner] = React.useState(false);
  const [extensionsThere, setExtensionsThere] = React.useState(false);
  const [agents, setAgents] = React.useState([]);

  const searchInputRef = React.useRef();

  const getData = (status, offset) => {
    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getAllLeads`,
      data: {
        filters: [
          selectedFilter.value >= 0 ? { name: 'Status', op: 'eq', value: selectedFilter.value } : null,
          selectedCountryFilter.value !== null ? { name: 'Country', op: 'eq', value: selectedCountryFilter.value } : null,
          status >= 0 ? { name: 'Status', op: 'eq', value: status } : null,
          !props.userData?.Flags.isAdmin && !props.userData?.Flags.isCCmanager ? { name: 'ResponsiblePerson', op: 'eq', value: props.userData?.ID } : null
        ],
        token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
        limit: status >= 0 ? 20 : null,
        offset: offset
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        if (status >= 0) {
          switch (status) {
            case 0:
              setDataNew(res.data);
              break;
            case 1:
              setDataConf(res.data);
              break;
            case 8:
              setDataPjk(res.data);
              break;
            case 2:
              setDataDelivery(res.data);
              break;
            case 3:
              setDataPaid(res.data);
              break;
            case 4:
              setDataReject(res.data);
              break;
            case 5:
              setDataCall(res.data);
              break;
            case 6:
              setDataWrong(res.data);
              break;
            case 7:
              setDataNa(res.data);
              break;
            case 9:
              setDataNa4(res.data);
              break;
            case 10:
              setDataNa6(res.data);
              break;
            case 11:
              setDataLastC(res.data);
              break;
            case 12:
              setDataTrash(res.data);
              break;
          }
        }
        else {
          setData(res.data);
        }
      } else {
        return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch((e) => {
      return setMessage('Server ne reagira...');
    }).finally(() => {
      setSpinner(false);
    });
  }

  const getNext20 = (status, offset) => {
    setPaginationSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getAllLeads`,
      data: {
        filters: [
          { name: 'Status', op: 'eq', value: status }
        ],
        token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
        limit: 20,
        offset: offset
      },
      ...backendModule.axiosConfig
    }).then(res => {
      switch (status) {
        case 0:
          setDataNew(prev => {
            return {
              ...prev,
              data: [...prev.data, ...res.data.data]
            }
          });
          break;
        case 1:
          setDataConf(prev => {
            return {
              ...prev,
              data: [...prev.data, ...res.data.data]
            }
          });
          break;
        case 2:
          setDataDelivery(prev => {
            return {
              ...prev,
              data: [...prev.data, ...res.data.data]
            }
          });
          break;
        case 3:
          setDataPaid(prev => {
            return {
              ...prev,
              data: [...prev.data, ...res.data.data]
            }
          });
          break;
        case 4:
          setDataReject(prev => {
            return {
              ...prev,
              data: [...prev.data, ...res.data.data]
            }
          });
          break;
        case 5:
          setDataCall(prev => {
            return {
              ...prev,
              data: [...prev.data, ...res.data.data]
            }
          });
          break;
        case 6:
          setDataWrong(prev => {
            return {
              ...prev,
              data: [...prev.data, ...res.data.data]
            }
          });
          break;
        case 7:
          setDataNa(prev => {
            return {
              ...prev,
              data: [...prev.data, ...res.data.data]
            }
          });
          break;
        case 8:
          setDataPjk(prev => {
            return {
              ...prev,
              data: [...prev.data, ...res.data.data]
            }
          });
          break;
        case 7:
          setDataNa(prev => {
            return {
              ...prev,
              data: [...prev.data, ...res.data.data]
            }
          });
          break;
        case 9:
          setDataNa4(prev => {
            return {
              ...prev,
              data: [...prev.data, ...res.data.data]
            }
          });
          break;
        case 10:
          setDataNa6(prev => {
            return {
              ...prev,
              data: [...prev.data, ...res.data.data]
            }
          });
          break;
        case 11:
          setDataLastC(prev => {
            return {
              ...prev,
              data: [...prev.data, ...res.data.data]
            }
          });
          break;
        case 12:
          setDataTrash(prev => {
            return {
              ...prev,
              data: [...prev.data, ...res.data.data]
            }
          });
          break;
      }
    }).finally(() => {
      setPaginationSpinner(false);
    });
  }

  const search = (type) => {
    if (searchInputRef.current.value === '') {
      setSearchResult([]);
      return;
    }

    setSpinner(true);

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getAllLeads`,
      data: {
        filters: [
          type === 'name' ? { name: 'Name', op: 'like', value: searchInputRef.current.value } : null,
          type === 'hash' ? { name: 'LeadHash', op: 'like', value: searchInputRef.current.value } : null,
          type === 'phone' ? { name: 'Phone', op: 'like', value: searchInputRef.current.value } : null,
        ],
        token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        setSearchResult(res.data);
      }
    }).finally(() => {
      setSpinner(false);
    });
  }

  const changeStatus = (id, status, oldStatus) => {
    setSecSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/changeStatus`,
      data: {
        ID: id,
        Status: status
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setStatusList({ LeadID: 'NaN', opened: false });
        getData(status);
        getData(oldStatus);
      } else {
        return setMessage('Došlo je do greške prilikom promjene statusa lead-a...');
      };
    }).catch(() => {
      return setMessage('Došlo je do greške prilikom promjene statusa lead-a...');
    }).finally(() => {
      setSecSpinner(false);
    });
  }

  const openStatusList = (leadID) => {
    if (statusList.LeadID !== leadID) {
      setStatusList({ LeadID: leadID, opened: true });
    }
    else {
      setStatusList({ LeadID: 'NaN', opened: false });
    }
  }

  const checkForTodayData = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/extensions/checkForTodayData`,
      data: {},
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data?.data?.length > 0) {
        setExtensionsThere(true);
      }
      else {
        setExtensionsThere(false);
      }
    }).catch(() => {

    });
  }

  const getAgents = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/users/getAllUsers`,
      data: {
        filters: [{ name: "GetLeads", op: "eq", value: true }],
      },
      ...backendModule.axiosConfig
    }).then(res => {
      setAgents(res.data);
    }).catch(() => {
      setAgents(backendModule.genericError);
    });
  }

  React.useEffect(() => {
    checkForTodayData();
    getAgents();
  }, []);

  React.useEffect(() => {
    getData(0);
    getData(1);
    getData(2);
    getData(3);
    getData(4);
    getData(5);
    getData(6);
    getData(7);
    getData(8);
    getData(9);
    getData(10);
    getData(11);
    getData(12);
    if (selectedFilter.value >= 0 || searchResult.data) getData();
  }, [selectedFilter, selectedCountryFilter]);

  return (
    <div className='route__kanban'>
      {extensionsThere && <div className='route__kanban__notifications'>
        <img onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode }, <ExstensionsModal getAll={checkForTodayData} />)} src='images/extensions.png' alt='' />
        <img onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode }, <CallAgainModal getAll={checkForTodayData} />)} src='images/callAgain.png' alt='' />
      </div>}
      <div className='route__kanban__header'>
        <p>Kanban</p>
        <div className='filters'>
          <div className='route__kanban__header__searchBar'>
            <img onClick={() => search('hash')} src='images/header_hashSearch.png' alt='' />
            <input ref={searchInputRef} type='text' placeholder='Pretraži...' />
            <img onClick={() => search('name')} src='images/header__searchW.png' alt='' />
            <img onClick={() => search('phone')} src='images/call.png' alt='' />
          </div>
          <div className='route__kanban__header__dropdown'>
            <div onClick={() => setDropdown(d => !d)} className={`route__kanban__header__dropdown__head ${dropdown ? 'route__kanban__header__dropdown__head--active' : ''}`}>
              <p>{selectedFilter.name}</p>
            </div>
            <div className={`route__kanban__header__dropdown__body ${dropdown ? 'route__kanban__header__dropdown__body--active' : ''}`}>
              <p onClick={() => { setSelectedFilter({ name: 'Novi upiti', value: 0 }); setDropdown(false) }}>Novi upiti</p>
              <p onClick={() => { setSelectedFilter({ name: 'Potvrđeno', value: 1 }); setDropdown(false) }}>Potvrđeno</p>
              <p onClick={() => { setSelectedFilter({ name: 'U isporuci', value: 2 }); setDropdown(false) }}>U isporuci</p>
              <p onClick={() => { setSelectedFilter({ name: 'Naplaćeno', value: 3 }); setDropdown(false) }}>Naplaćeno</p>
              <p onClick={() => { setSelectedFilter({ name: 'Odbijeno', value: 4 }); setDropdown(false) }}>Odbijeno</p>
              <p onClick={() => { setSelectedFilter({ name: 'Pozvati ponovo', value: 5 }); setDropdown(false) }}>Pozvati ponovo</p>
              <p onClick={() => { setSelectedFilter({ name: 'Loš broj', value: 6 }); setDropdown(false) }}>Loš broj</p>
              <p onClick={() => { setSelectedFilter({ name: 'Ne javlja se', value: 7 }); setDropdown(false) }}>Ne javlja se</p>
              <p onClick={() => { setSelectedFilter({ name: 'Ne javlja se x4', value: 9 }); setDropdown(false) }}>Ne javlja se x4</p>
              <p onClick={() => { setSelectedFilter({ name: 'Ne javlja se x6', value: 10 }); setDropdown(false) }}>Ne javlja se x6</p>
              <p onClick={() => { setSelectedFilter({ name: 'Zadnja šansa', value: 11 }); setDropdown(false) }}>Zadnja šansa</p>
              <p onClick={() => { setSelectedFilter({ name: 'Smeće', value: 12 }); setDropdown(false) }}>Smeće</p>
              <p onClick={() => { setSelectedFilter({ name: 'PJK', value: 8 }); setDropdown(false) }}>PJK</p>
              <p onClick={() => { setSelectedFilter({ name: 'Sve', value: -1 }); setDropdown(false) }}>Sve</p>
            </div>
          </div>
          <div className='route__kanban__header__cdropdown'>
            <div onClick={() => setCountryDropdown(d => !d)} className={`route__kanban__header__cdropdown__head ${countryDropdown ? 'route__kanban__header__cdropdown__head--active' : ''}`}>
              <p>{selectedCountryFilter.name}</p>
            </div>
            <div className={`route__kanban__header__cdropdown__body ${countryDropdown ? 'route__kanban__header__cdropdown__body--active' : ''}`}>
              <p onClick={() => { setSelectedCountryFilter({ name: 'BiH', value: 'BA' }); setCountryDropdown(false) }}>BiH</p>
              <p onClick={() => { setSelectedCountryFilter({ name: 'Srbija', value: 'RS' }); setCountryDropdown(false) }}>Srbija</p>
              <p onClick={() => { setSelectedCountryFilter({ name: 'Sve', value: null }); setCountryDropdown(false) }}>Sve</p>
            </div>
          </div>
        </div>
      </div>
      {message && <p style={{ color: 'red' }}>{message}</p>}
      {spinner && <Spinner align='center' style={{ marginTop: '20px' }} color='rgb(19, 33, 60)' />}
      {!message && !spinner && selectedFilter.value === -1 && !searchResult.data && <div className='route__kanban__content'>
        <div className='route__kanban__content__column'>
          <p style={{ background: '#13213C' }}>Novi upiti ({dataNew?.data?.length})</p>
          <div className='route__kanban__content__column__list'>
            {
              dataNew?.data?.map((item) => {
                return <div className='route__kanban__content__column__list__item'>
                  <div onClick={(e) => animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={0} lead={item} agents={agents} />)}>
                    <p>ID: #{item.ID}</p>
                    <p>Ime: {item.Name}</p>
                    <p>Proizvod: {item.Product}</p>
                    <p>Država: {item.Country}</p>
                    <p>Oznaka: {item.Tag}</p>
                    <p>Količina: {item.Quantity}</p>
                    <p>Cijena: {item.Price} {item.Country === 'RS' ? 'RSD' : 'KM'}</p>
                    <p>Pristiglo: {moment(item.createdAt).format('DD.MM.YYYY hh:mm:ss A')}</p>
                  </div>
                  {!secSpinner && <div className='route__kanban__content__column__list__item__status'>
                    <div onClick={() => openStatusList(item.ID)} className='route__kanban__content__column__list__item__status__changeStatus'><p>{statusList.LeadID === item.ID && statusList.opened ? 'Zatvori listu' : 'Promijeni status'}</p></div>
                    <div className={`${statusList.LeadID === item.ID && statusList.opened ? 'route__kanban__content__column__list__item__status__statusList route__kanban__content__column__list__item__status__statusList--active' : 'route__kanban__content__column__list__item__status__statusList'}`}>
                      <strong onClick={(e) => { changeStatus(item.ID, 0, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={0} lead={item} agents={agents} />) }} style={{ background: 'rgb(19, 33, 60)' }}>Novi upiti</strong>
                      <strong onClick={(e) => { changeStatus(item.ID, 2, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={0} lead={item} agents={agents} />) }} style={{ background: 'rgb(239, 149, 0)' }}>U isporuci</strong>
                      <strong onClick={(e) => { changeStatus(item.ID, 3, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={0} lead={item} agents={agents} />) }} style={{ background: 'rgba(33, 163, 9, 0.86)' }}>Naplaćeno</strong>
                      <strong onClick={(e) => { changeStatus(item.ID, 4, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={0} lead={item} agents={agents} />) }} style={{ background: 'rgba(233, 43, 43, 0.88)' }}>Odbijeno</strong>
                      <strong onClick={(e) => { changeStatus(item.ID, 5, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={0} lead={item} agents={agents} />) }} style={{ background: 'rgb(76, 56, 124)' }}>Pozvati ponovo</strong>
                      <strong onClick={(e) => { changeStatus(item.ID, 6, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={0} lead={item} agents={agents} />) }} style={{ background: 'rgb(143, 88, 88)' }}>Loš broj</strong>
                      <strong onClick={(e) => { changeStatus(item.ID, 7, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={0} lead={item} agents={agents} />) }} style={{ background: 'rgb(253, 94, 115)' }}>Ne javlja se</strong>
                      <strong onClick={(e) => { changeStatus(item.ID, 9, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={0} lead={item} agents={agents} />) }} style={{ background: 'rgb(213 53 74)' }}>Ne javlja se x4</strong>
                      <strong onClick={(e) => { changeStatus(item.ID, 10, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={0} lead={item} agents={agents} />) }} style={{ background: 'rgb(161 20 39)' }}>Ne javlja se x6</strong>
                      <strong onClick={(e) => { changeStatus(item.ID, 11, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={0} lead={item} agents={agents} />) }} style={{ background: 'rgb(89 0 12)' }}>Zadnja šansa</strong>
                      <strong onClick={(e) => { changeStatus(item.ID, 12, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={0} lead={item} agents={agents} />) }} style={{ background: 'rgb(77 0 70)' }}>Smeće</strong>
                      <strong onClick={(e) => { changeStatus(item.ID, 8, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={0} lead={item} agents={agents} />) }} style={{ background: 'rgb(97 97 97)' }}>PJK</strong>
                    </div>
                  </div>}
                  {secSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
                </div>
              })
            }
            {paginationSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
            {dataNew?.data?.length >= 20 && <div className='route__kanban__content__column__list__paginationButton' onClick={() => getNext20(0, dataNew?.data?.length)}>
              <p>Učitaj još...</p>
            </div>}
          </div>
        </div>
        <div className='route__kanban__content__column'>
          <p style={{ background: '#08b555c2' }}>Potvrđeno</p>
          <div className='route__kanban__content__column__list'>
            {
              dataConf?.data?.map((item) => {
                return <div className='route__kanban__content__column__list__item'>
                  <div onClick={(e) => animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={1} lead={item} agents={agents} />)}>
                    <p>ID: #{item.ID}</p>
                    <p>Ime: {item.Name}</p>
                    <p>Proizvod: {item.Product}</p>
                    <p>Država: {item.Country}</p>
                    <p>Oznaka: {item.Tag}</p>
                    <p>Količina: {item.Quantity}</p>
                    <p>Cijena: {item.Price} {item.Country === 'RS' ? 'RSD' : 'KM'}</p>
                    <p>Pristiglo: {moment(item.createdAt).format('DD.MM.YYYY hh:mm:ss A')}</p>
                  </div>
                  {!secSpinner && <div className='route__kanban__content__column__list__item__status'>
                    <div onClick={() => openStatusList(item.ID)} className='route__kanban__content__column__list__item__status__changeStatus'><p>{statusList.LeadID === item.ID && statusList.opened ? 'Zatvori listu' : 'Promijeni status'}</p></div>
                    <div className={`${statusList.LeadID === item.ID && statusList.opened ? 'route__kanban__content__column__list__item__status__statusList route__kanban__content__column__list__item__status__statusList--active' : 'route__kanban__content__column__list__item__status__statusList'}`}>
                      <strong onClick={(e) => { changeStatus(item.ID, 0, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={1} lead={item} agents={agents} />) }} style={{ background: 'rgb(19, 33, 60)' }}>Novi upiti</strong>
                      
                      <strong onClick={(e) => { changeStatus(item.ID, 2, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={1} lead={item} agents={agents} />) }} style={{ background: 'rgb(239, 149, 0)' }}>U isporuci</strong>
                      <strong onClick={(e) => { changeStatus(item.ID, 3, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={1} lead={item} agents={agents} />) }} style={{ background: 'rgba(33, 163, 9, 0.86)' }}>Naplaćeno</strong>
                      <strong onClick={(e) => { changeStatus(item.ID, 4, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={1} lead={item} agents={agents} />) }} style={{ background: 'rgba(233, 43, 43, 0.88)' }}>Odbijeno</strong>
                      <strong onClick={(e) => { changeStatus(item.ID, 5, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={1} lead={item} agents={agents} />) }} style={{ background: 'rgb(76, 56, 124)' }}>Pozvati ponovo</strong>
                      <strong onClick={(e) => { changeStatus(item.ID, 6, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={1} lead={item} agents={agents} />) }} style={{ background: 'rgb(143, 88, 88)' }}>Loš broj</strong>
                      <strong onClick={(e) => { changeStatus(item.ID, 7, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={1} lead={item} agents={agents} />) }} style={{ background: 'rgb(253, 94, 115)' }}>Ne javlja se</strong>
                      <strong onClick={(e) => { changeStatus(item.ID, 9, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={1} lead={item} agents={agents} />) }} style={{ background: 'rgb(213 53 74)' }}>Ne javlja se x4</strong>
                      <strong onClick={(e) => { changeStatus(item.ID, 10, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={1} lead={item} agents={agents} />) }} style={{ background: 'rgb(161 20 39)' }}>Ne javlja se x6</strong>
                      <strong onClick={(e) => { changeStatus(item.ID, 11, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={1} lead={item} agents={agents} />) }} style={{ background: 'rgb(89 0 12)' }}>Zadnja šansa</strong>
                      <strong onClick={(e) => { changeStatus(item.ID, 12, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={1} lead={item} agents={agents} />) }} style={{ background: 'rgb(77 0 70)' }}>Smeće</strong>
                      <strong onClick={(e) => { changeStatus(item.ID, 8, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={1} lead={item} agents={agents} />) }} style={{ background: 'rgb(97 97 97)' }}>PJK</strong>
                    </div>
                  </div>}
                  {secSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
                </div>
              })
            }
            {paginationSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
            {dataConf?.data?.length >= 20 && <div className='route__kanban__content__column__list__paginationButton' onClick={() => getNext20(1, dataConf?.data?.length)}>
              <p>Učitaj još...</p>
            </div>}
          </div>
        </div>
        <div className='route__kanban__content__column'>
          <p style={{ background: 'rgb(97 97 97)' }}>PJK</p>
          <div className='route__kanban__content__column__list'>
            {
              dataPjk?.data?.map((item) => {
                if (item.Status === 8) {
                  return <div className='route__kanban__content__column__list__item'>
                    <div onClick={(e) => animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={8} lead={item} agents={agents} />)}>
                      <p>ID: #{item.ID}</p>
                      <p>Ime: {item.Name}</p>
                      <p>Proizvod: {item.Product}</p>
                      <p>Država: {item.Country}</p>
                      <p>Oznaka: {item.Tag}</p>
                      <p>Količina: {item.Quantity}</p>
                      <p>Cijena: {item.Price} {item.Country === 'RS' ? 'RSD' : 'KM'}</p>
                      <p>Pristiglo: {moment(item.createdAt).format('DD.MM.YYYY hh:mm:ss A')}</p>
                    </div>
                    {!secSpinner && <div className='route__kanban__content__column__list__item__status'>
                      <div onClick={() => openStatusList(item.ID)} className='route__kanban__content__column__list__item__status__changeStatus'><p>{statusList.LeadID === item.ID && statusList.opened ? 'Zatvori listu' : 'Promijeni status'}</p></div>
                      <div className={`${statusList.LeadID === item.ID && statusList.opened ? 'route__kanban__content__column__list__item__status__statusList route__kanban__content__column__list__item__status__statusList--active' : 'route__kanban__content__column__list__item__status__statusList'}`}>
                        <strong onClick={(e) => { changeStatus(item.ID, 0, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={8} lead={item} agents={agents} />) }} style={{ background: 'rgb(19, 33, 60)' }}>Novi upiti</strong>
                        
                        <strong onClick={(e) => { changeStatus(item.ID, 2, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={8} lead={item} agents={agents} />) }} style={{ background: 'rgb(239, 149, 0)' }}>U isporuci</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 3, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={8} lead={item} agents={agents} />) }} style={{ background: 'rgba(33, 163, 9, 0.86)' }}>Naplaćeno</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 4, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={8} lead={item} agents={agents} />) }} style={{ background: 'rgba(233, 43, 43, 0.88)' }}>Odbijeno</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 5, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={8} lead={item} agents={agents} />) }} style={{ background: 'rgb(76, 56, 124)' }}>Pozvati ponovo</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 6, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={8} lead={item} agents={agents} />) }} style={{ background: 'rgb(143, 88, 88)' }}>Loš broj</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 7, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={8} lead={item} agents={agents} />) }} style={{ background: 'rgb(253, 94, 115)' }}>Ne javlja se</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 9, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={8} lead={item} agents={agents} />) }} style={{ background: 'rgb(213 53 74)' }}>Ne javlja se x4</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 10, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={8} lead={item} agents={agents} />) }} style={{ background: 'rgb(161 20 39)' }}>Ne javlja se x6</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 11, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={8} lead={item} agents={agents} />) }} style={{ background: 'rgb(89 0 12)' }}>Zadnja šansa</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 12, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={8} lead={item} agents={agents} />) }} style={{ background: 'rgb(77 0 70)' }}>Smeće</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 8, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={8} lead={item} agents={agents} />) }} style={{ background: 'rgb(97 97 97)' }}>PJK</strong>
                      </div>
                    </div>}
                    {secSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
                  </div>
                }
              })
            }
            {paginationSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
            {dataPjk?.data?.length >= 20 && <div className='route__kanban__content__column__list__paginationButton' onClick={() => getNext20(8, dataPjk?.data?.length)}>
              <p>Učitaj još...</p>
            </div>}
          </div>
        </div>
        <div className='route__kanban__content__column'>
          <p style={{ background: '#EF9500' }}>U isporuci</p>
          <div className='route__kanban__content__column__list'>
            {
              dataDelivery?.data?.map((item) => {
                if (item.Status === 2) {
                  return <div className='route__kanban__content__column__list__item'>
                    <div onClick={(e) => animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={2} lead={item} agents={agents} />)}>
                      <p>ID: #{item.ID}</p>
                      <p>Ime: {item.Name}</p>
                      <p>Proizvod: {item.Product}</p>
                      <p>Država: {item.Country}</p>
                      <p>Oznaka: {item.Tag}</p>
                      <p>Količina: {item.Quantity}</p>
                      <p>Cijena: {item.Price} {item.Country === 'RS' ? 'RSD' : 'KM'}</p>
                      <p>Pristiglo: {moment(item.createdAt).format('DD.MM.YYYY hh:mm:ss A')}</p>
                    </div>
                    {!secSpinner && <div className='route__kanban__content__column__list__item__status'>
                      <div onClick={() => openStatusList(item.ID)} className='route__kanban__content__column__list__item__status__changeStatus'><p>{statusList.LeadID === item.ID && statusList.opened ? 'Zatvori listu' : 'Promijeni status'}</p></div>
                      <div className={`${statusList.LeadID === item.ID && statusList.opened ? 'route__kanban__content__column__list__item__status__statusList route__kanban__content__column__list__item__status__statusList--active' : 'route__kanban__content__column__list__item__status__statusList'}`}>
                        <strong onClick={(e) => { changeStatus(item.ID, 0, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(19, 33, 60)' }}>Novi upiti</strong>
                        
                        <strong onClick={(e) => { changeStatus(item.ID, 2, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(239, 149, 0)' }}>U isporuci</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 3, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgba(33, 163, 9, 0.86)' }}>Naplaćeno</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 4, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgba(233, 43, 43, 0.88)' }}>Odbijeno</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 5, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(76, 56, 124)' }}>Pozvati ponovo</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 6, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(143, 88, 88)' }}>Loš broj</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 7, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(253, 94, 115)' }}>Ne javlja se</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 9, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(213 53 74)' }}>Ne javlja se x4</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 10, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(161 20 39)' }}>Ne javlja se x6</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 11, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(89 0 12)' }}>Zadnja šansa</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 12, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(77 0 70)' }}>Smeće</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 8, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(97 97 97)' }}>PJK</strong>
                      </div>
                    </div>}
                    {secSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
                  </div>
                }
              })
            }
            {paginationSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
            {dataDelivery?.data?.length >= 20 && <div className='route__kanban__content__column__list__paginationButton' onClick={() => getNext20(2, dataDelivery?.data?.length)}>
              <p>Učitaj još...</p>
            </div>}
          </div>
        </div>
        <div className='route__kanban__content__column'>
          <p style={{ background: 'rgb(33 163 9 / 86%)' }}>Naplaćeno</p>
          <div className='route__kanban__content__column__list'>
            {
              dataPaid?.data?.map((item) => {
                if (item.Status === 3) {
                  return <div className='route__kanban__content__column__list__item'>
                    <div onClick={(e) => animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={3} lead={item} agents={agents} />)}>
                      <p>ID: #{item.ID}</p>
                      <p>Ime: {item.Name}</p>
                      <p>Proizvod: {item.Product}</p>
                      <p>Država: {item.Country}</p>
                      <p>Oznaka: {item.Tag}</p>
                      <p>Količina: {item.Quantity}</p>
                      <p>Cijena: {item.Price} {item.Country === 'RS' ? 'RSD' : 'KM'}</p>
                      <p>Pristiglo: {moment(item.createdAt).format('DD.MM.YYYY hh:mm:ss A')}</p>
                    </div>
                    {!secSpinner && <div className='route__kanban__content__column__list__item__status'>
                      <div onClick={() => openStatusList(item.ID)} className='route__kanban__content__column__list__item__status__changeStatus'><p>{statusList.LeadID === item.ID && statusList.opened ? 'Zatvori listu' : 'Promijeni status'}</p></div>
                      <div className={`${statusList.LeadID === item.ID && statusList.opened ? 'route__kanban__content__column__list__item__status__statusList route__kanban__content__column__list__item__status__statusList--active' : 'route__kanban__content__column__list__item__status__statusList'}`}>
                        <strong onClick={(e) => { changeStatus(item.ID, 0, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(19, 33, 60)' }}>Novi upiti</strong>
                        
                        <strong onClick={(e) => { changeStatus(item.ID, 2, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(239, 149, 0)' }}>U isporuci</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 3, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgba(33, 163, 9, 0.86)' }}>Naplaćeno</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 4, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgba(233, 43, 43, 0.88)' }}>Odbijeno</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 5, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(76, 56, 124)' }}>Pozvati ponovo</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 6, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(143, 88, 88)' }}>Loš broj</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 7, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(253, 94, 115)' }}>Ne javlja se</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 9, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(213 53 74)' }}>Ne javlja se x4</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 10, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(161 20 39)' }}>Ne javlja se x6</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 11, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(89 0 12)' }}>Zadnja šansa</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 12, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(77 0 70)' }}>Smeće</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 8, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(97 97 97)' }}>PJK</strong>
                      </div>
                    </div>}
                    {secSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
                  </div>
                }
              })
            }
            {paginationSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
            {dataPaid?.data?.length >= 20 && <div className='route__kanban__content__column__list__paginationButton' onClick={() => getNext20(3, dataPaid?.data?.length)}>
              <p>Učitaj još...</p>
            </div>}
          </div>
        </div>
        <div className='route__kanban__content__column'>
          <p style={{ background: 'rgb(233 43 43 / 88%)' }}>Odbijeno</p>
          <div className='route__kanban__content__column__list'>
            {
              dataReject?.data?.map((item) => {
                if (item.Status === 4) {
                  return <div className='route__kanban__content__column__list__item'>
                    <div onClick={(e) => animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={4} lead={item} agents={agents} />)}>
                      <p>ID: #{item.ID}</p>
                      <p>Ime: {item.Name}</p>
                      <p>Proizvod: {item.Product}</p>
                      <p>Država: {item.Country}</p>
                      <p>Oznaka: {item.Tag}</p>
                      <p>Količina: {item.Quantity}</p>
                      <p>Cijena: {item.Price} {item.Country === 'RS' ? 'RSD' : 'KM'}</p>
                      <p>Pristiglo: {moment(item.createdAt).format('DD.MM.YYYY hh:mm:ss A')}</p>
                    </div>
                    {!secSpinner && <div className='route__kanban__content__column__list__item__status'>
                      <div onClick={() => openStatusList(item.ID)} className='route__kanban__content__column__list__item__status__changeStatus'><p>{statusList.LeadID === item.ID && statusList.opened ? 'Zatvori listu' : 'Promijeni status'}</p></div>
                      <div className={`${statusList.LeadID === item.ID && statusList.opened ? 'route__kanban__content__column__list__item__status__statusList route__kanban__content__column__list__item__status__statusList--active' : 'route__kanban__content__column__list__item__status__statusList'}`}>
                        <strong onClick={(e) => { changeStatus(item.ID, 0, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(19, 33, 60)' }}>Novi upiti</strong>
                        
                        <strong onClick={(e) => { changeStatus(item.ID, 2, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(239, 149, 0)' }}>U isporuci</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 3, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgba(33, 163, 9, 0.86)' }}>Naplaćeno</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 4, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgba(233, 43, 43, 0.88)' }}>Odbijeno</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 5, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(76, 56, 124)' }}>Pozvati ponovo</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 6, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(143, 88, 88)' }}>Loš broj</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 7, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(253, 94, 115)' }}>Ne javlja se</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 9, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(213 53 74)' }}>Ne javlja se x4</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 10, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(161 20 39)' }}>Ne javlja se x6</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 11, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(89 0 12)' }}>Zadnja šansa</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 12, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(77 0 70)' }}>Smeće</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 8, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(97 97 97)' }}>PJK</strong>
                      </div>
                    </div>}
                    {secSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
                  </div>
                }
              })
            }
            {paginationSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
            {dataReject?.data?.length >= 20 && <div className='route__kanban__content__column__list__paginationButton' onClick={() => getNext20(4, dataReject?.data?.length)}>
              <p>Učitaj još...</p>
            </div>}
          </div>
        </div>
        <div className='route__kanban__content__column'>
          <p style={{ background: 'rgb(76 56 124)' }}>Pozvati ponovo</p>
          <div className='route__kanban__content__column__list'>
            {
              dataCall?.data?.map((item) => {
                if (item.Status === 5) {
                  return <div className='route__kanban__content__column__list__item'>
                    <div onClick={(e) => animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={5} lead={item} agents={agents} />)}>
                      <p>ID: #{item.ID}</p>
                      <p>Ime: {item.Name}</p>
                      <p>Proizvod: {item.Product}</p>
                      <p>Država: {item.Country}</p>
                      <p>Oznaka: {item.Tag}</p>
                      <p>Količina: {item.Quantity}</p>
                      <p>Cijena: {item.Price} {item.Country === 'RS' ? 'RSD' : 'KM'}</p>
                      <p>Pristiglo: {moment(item.createdAt).format('DD.MM.YYYY hh:mm:ss A')}</p>
                    </div>
                    {!secSpinner && <div className='route__kanban__content__column__list__item__status'>
                      <div onClick={() => openStatusList(item.ID)} className='route__kanban__content__column__list__item__status__changeStatus'><p>{statusList.LeadID === item.ID && statusList.opened ? 'Zatvori listu' : 'Promijeni status'}</p></div>
                      <div className={`${statusList.LeadID === item.ID && statusList.opened ? 'route__kanban__content__column__list__item__status__statusList route__kanban__content__column__list__item__status__statusList--active' : 'route__kanban__content__column__list__item__status__statusList'}`}>
                        <strong onClick={(e) => { changeStatus(item.ID, 0, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(19, 33, 60)' }}>Novi upiti</strong>
                        
                        <strong onClick={(e) => { changeStatus(item.ID, 2, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(239, 149, 0)' }}>U isporuci</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 3, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgba(33, 163, 9, 0.86)' }}>Naplaćeno</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 4, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgba(233, 43, 43, 0.88)' }}>Odbijeno</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 5, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(76, 56, 124)' }}>Pozvati ponovo</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 6, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(143, 88, 88)' }}>Loš broj</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 7, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(253, 94, 115)' }}>Ne javlja se</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 9, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(213 53 74)' }}>Ne javlja se x4</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 10, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(161 20 39)' }}>Ne javlja se x6</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 11, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(89 0 12)' }}>Zadnja šansa</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 12, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(77 0 70)' }}>Smeće</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 8, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(97 97 97)' }}>PJK</strong>
                      </div>
                    </div>}
                    {secSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
                  </div>
                }
              })
            }
            {paginationSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
            {dataCall?.data?.length >= 20 && <div className='route__kanban__content__column__list__paginationButton' onClick={() => getNext20(5, dataCall?.data?.length)}>
              <p>Učitaj još...</p>
            </div>}
          </div>
        </div>
        <div className='route__kanban__content__column'>
          <p style={{ background: 'rgb(143 88 88)' }}>Loš broj</p>
          <div className='route__kanban__content__column__list'>
            {
              dataWrong?.data?.map((item) => {
                if (item.Status === 6) {
                  return <div className='route__kanban__content__column__list__item'>
                    <div onClick={(e) => animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={6} lead={item} agents={agents} />)}>
                      <p>ID: #{item.ID}</p>
                      <p>Ime: {item.Name}</p>
                      <p>Proizvod: {item.Product}</p>
                      <p>Država: {item.Country}</p>
                      <p>Oznaka: {item.Tag}</p>
                      <p>Količina: {item.Quantity}</p>
                      <p>Cijena: {item.Price} {item.Country === 'RS' ? 'RSD' : 'KM'}</p>
                      <p>Pristiglo: {moment(item.createdAt).format('DD.MM.YYYY hh:mm:ss A')}</p>
                    </div>
                    {!secSpinner && <div className='route__kanban__content__column__list__item__status'>
                      <div onClick={() => openStatusList(item.ID)} className='route__kanban__content__column__list__item__status__changeStatus'><p>{statusList.LeadID === item.ID && statusList.opened ? 'Zatvori listu' : 'Promijeni status'}</p></div>
                      <div className={`${statusList.LeadID === item.ID && statusList.opened ? 'route__kanban__content__column__list__item__status__statusList route__kanban__content__column__list__item__status__statusList--active' : 'route__kanban__content__column__list__item__status__statusList'}`}>
                        <strong onClick={(e) => { changeStatus(item.ID, 0, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(19, 33, 60)' }}>Novi upiti</strong>
                        
                        <strong onClick={(e) => { changeStatus(item.ID, 2, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(239, 149, 0)' }}>U isporuci</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 3, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgba(33, 163, 9, 0.86)' }}>Naplaćeno</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 4, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgba(233, 43, 43, 0.88)' }}>Odbijeno</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 5, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(76, 56, 124)' }}>Pozvati ponovo</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 6, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(143, 88, 88)' }}>Loš broj</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 7, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(253, 94, 115)' }}>Ne javlja se</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 9, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(213 53 74)' }}>Ne javlja se x4</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 10, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(161 20 39)' }}>Ne javlja se x6</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 11, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(89 0 12)' }}>Zadnja šansa</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 12, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(77 0 70)' }}>Smeće</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 8, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(97 97 97)' }}>PJK</strong>
                      </div>
                    </div>}
                    {secSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
                  </div>
                }
              })
            }
            {paginationSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
            {dataWrong?.data?.length >= 20 && <div className='route__kanban__content__column__list__paginationButton' onClick={() => getNext20(6, dataWrong?.data?.length)}>
              <p>Učitaj još...</p>
            </div>}
          </div>
        </div>
        <div className='route__kanban__content__column'>
          <p style={{ background: 'rgb(253 94 115)' }}>Ne javlja se</p>
          <div className='route__kanban__content__column__list'>
            {
              dataNa?.data?.map((item) => {
                if (item.Status === 7) {
                  return <div className='route__kanban__content__column__list__item'>
                    <div onClick={(e) => animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={7} lead={item} agents={agents} />)}>
                      <p>ID: #{item.ID}</p>
                      <p>Ime: {item.Name}</p>
                      <p>Proizvod: {item.Product}</p>
                      <p>Država: {item.Country}</p>
                      <p>Oznaka: {item.Tag}</p>
                      <p>Količina: {item.Quantity}</p>
                      <p>Cijena: {item.Price} {item.Country === 'RS' ? 'RSD' : 'KM'}</p>
                      <p>Pristiglo: {moment(item.createdAt).format('DD.MM.YYYY hh:mm:ss A')}</p>
                    </div>
                    {!secSpinner && <div className='route__kanban__content__column__list__item__status'>
                      <div onClick={() => openStatusList(item.ID)} className='route__kanban__content__column__list__item__status__changeStatus'><p>{statusList.LeadID === item.ID && statusList.opened ? 'Zatvori listu' : 'Promijeni status'}</p></div>
                      <div className={`${statusList.LeadID === item.ID && statusList.opened ? 'route__kanban__content__column__list__item__status__statusList route__kanban__content__column__list__item__status__statusList--active' : 'route__kanban__content__column__list__item__status__statusList'}`}>
                        <strong onClick={(e) => { changeStatus(item.ID, 0, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(19, 33, 60)' }}>Novi upiti</strong>
                        
                        <strong onClick={(e) => { changeStatus(item.ID, 2, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(239, 149, 0)' }}>U isporuci</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 3, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgba(33, 163, 9, 0.86)' }}>Naplaćeno</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 4, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgba(233, 43, 43, 0.88)' }}>Odbijeno</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 5, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(76, 56, 124)' }}>Pozvati ponovo</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 6, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(143, 88, 88)' }}>Loš broj</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 7, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(253, 94, 115)' }}>Ne javlja se</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 9, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(213 53 74)' }}>Ne javlja se x4</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 10, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(161 20 39)' }}>Ne javlja se x6</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 11, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(89 0 12)' }}>Zadnja šansa</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 12, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(77 0 70)' }}>Smeće</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 8, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(97 97 97)' }}>PJK</strong>
                      </div>
                    </div>}
                    {secSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
                  </div>
                }
              })
            }
            {paginationSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
            {dataNa?.data?.length >= 20 && <div className='route__kanban__content__column__list__paginationButton' onClick={() => getNext20(7, dataNa?.data?.length)}>
              <p>Učitaj još...</p>
            </div>}
          </div>
        </div>
        <div className='route__kanban__content__column'>
          <p style={{ background: 'rgb(213, 53, 74)' }}>Ne javlja se x4 </p>
          <div className='route__kanban__content__column__list'>
            {
              dataNa4?.data?.map((item) => {
                if (item.Status === 9) {
                  return <div className='route__kanban__content__column__list__item'>
                    <div onClick={(e) => animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={9} lead={item} agents={agents} />)}>
                      <p>ID: #{item.ID}</p>
                      <p>Ime: {item.Name}</p>
                      <p>Proizvod: {item.Product}</p>
                      <p>Država: {item.Country}</p>
                      <p>Oznaka: {item.Tag}</p>
                      <p>Količina: {item.Quantity}</p>
                      <p>Cijena: {item.Price} {item.Country === 'RS' ? 'RSD' : 'KM'}</p>
                      <p>Pristiglo: {moment(item.createdAt).format('DD.MM.YYYY hh:mm:ss A')}</p>
                    </div>
                    {!secSpinner && <div className='route__kanban__content__column__list__item__status'>
                      <div onClick={() => openStatusList(item.ID)} className='route__kanban__content__column__list__item__status__changeStatus'><p>{statusList.LeadID === item.ID && statusList.opened ? 'Zatvori listu' : 'Promijeni status'}</p></div>
                      <div className={`${statusList.LeadID === item.ID && statusList.opened ? 'route__kanban__content__column__list__item__status__statusList route__kanban__content__column__list__item__status__statusList--active' : 'route__kanban__content__column__list__item__status__statusList'}`}>
                        <strong onClick={(e) => { changeStatus(item.ID, 0, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(19, 33, 60)' }}>Novi upiti</strong>
                        
                        <strong onClick={(e) => { changeStatus(item.ID, 2, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(239, 149, 0)' }}>U isporuci</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 3, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgba(33, 163, 9, 0.86)' }}>Naplaćeno</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 4, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgba(233, 43, 43, 0.88)' }}>Odbijeno</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 5, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(76, 56, 124)' }}>Pozvati ponovo</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 6, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(143, 88, 88)' }}>Loš broj</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 7, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(253, 94, 115)' }}>Ne javlja se</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 9, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(213 53 74)' }}>Ne javlja se x4</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 10, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(161 20 39)' }}>Ne javlja se x6</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 11, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(89 0 12)' }}>Zadnja šansa</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 12, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(77 0 70)' }}>Smeće</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 8, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(97 97 97)' }}>PJK</strong>
                      </div>
                    </div>}
                    {secSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
                  </div>
                }
              })
            }
            {paginationSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
            {dataNa?.data?.length >= 20 && <div className='route__kanban__content__column__list__paginationButton' onClick={() => getNext20(9, dataNa?.data?.length)}>
              <p>Učitaj još...</p>
            </div>}
          </div>
        </div>
        <div className='route__kanban__content__column'>
          <p style={{ background: 'rgb(161, 20, 39)' }}>Ne javlja se x6 </p>
          <div className='route__kanban__content__column__list'>
            {
              dataNa6?.data?.map((item) => {
                if (item.Status === 10) {
                  return <div className='route__kanban__content__column__list__item'>
                    <div onClick={(e) => animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={10} lead={item} agents={agents} />)}>
                      <p>ID: #{item.ID}</p>
                      <p>Ime: {item.Name}</p>
                      <p>Proizvod: {item.Product}</p>
                      <p>Država: {item.Country}</p>
                      <p>Oznaka: {item.Tag}</p>
                      <p>Količina: {item.Quantity}</p>
                      <p>Cijena: {item.Price} {item.Country === 'RS' ? 'RSD' : 'KM'}</p>
                      <p>Pristiglo: {moment(item.createdAt).format('DD.MM.YYYY hh:mm:ss A')}</p>
                    </div>
                    {!secSpinner && <div className='route__kanban__content__column__list__item__status'>
                      <div onClick={() => openStatusList(item.ID)} className='route__kanban__content__column__list__item__status__changeStatus'><p>{statusList.LeadID === item.ID && statusList.opened ? 'Zatvori listu' : 'Promijeni status'}</p></div>
                      <div className={`${statusList.LeadID === item.ID && statusList.opened ? 'route__kanban__content__column__list__item__status__statusList route__kanban__content__column__list__item__status__statusList--active' : 'route__kanban__content__column__list__item__status__statusList'}`}>
                        <strong onClick={(e) => { changeStatus(item.ID, 0, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(19, 33, 60)' }}>Novi upiti</strong>
                        
                        <strong onClick={(e) => { changeStatus(item.ID, 2, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(239, 149, 0)' }}>U isporuci</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 3, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgba(33, 163, 9, 0.86)' }}>Naplaćeno</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 4, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgba(233, 43, 43, 0.88)' }}>Odbijeno</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 5, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(76, 56, 124)' }}>Pozvati ponovo</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 6, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(143, 88, 88)' }}>Loš broj</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 7, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(253, 94, 115)' }}>Ne javlja se</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 9, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(213 53 74)' }}>Ne javlja se x4</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 10, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(161 20 39)' }}>Ne javlja se x6</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 11, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(89 0 12)' }}>Zadnja šansa</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 12, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(77 0 70)' }}>Smeće</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 8, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(97 97 97)' }}>PJK</strong>
                      </div>
                    </div>}
                    {secSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
                  </div>
                }
              })
            }
            {paginationSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
            {dataNa?.data?.length >= 20 && <div className='route__kanban__content__column__list__paginationButton' onClick={() => getNext20(9, dataNa?.data?.length)}>
              <p>Učitaj još...</p>
            </div>}
          </div>
        </div>
        <div className='route__kanban__content__column'>
          <p style={{ background: 'rgb(89, 0, 12)' }}>Zadnja šansa </p>
          <div className='route__kanban__content__column__list'>
            {
              dataLastC?.data?.map((item) => {
                if (item.Status === 11) {
                  return <div className='route__kanban__content__column__list__item'>
                    <div onClick={(e) => animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={11} lead={item} agents={agents} />)}>
                      <p>ID: #{item.ID}</p>
                      <p>Ime: {item.Name}</p>
                      <p>Proizvod: {item.Product}</p>
                      <p>Država: {item.Country}</p>
                      <p>Oznaka: {item.Tag}</p>
                      <p>Količina: {item.Quantity}</p>
                      <p>Cijena: {item.Price} {item.Country === 'RS' ? 'RSD' : 'KM'}</p>
                      <p>Pristiglo: {moment(item.createdAt).format('DD.MM.YYYY hh:mm:ss A')}</p>
                    </div>
                    {!secSpinner && <div className='route__kanban__content__column__list__item__status'>
                      <div onClick={() => openStatusList(item.ID)} className='route__kanban__content__column__list__item__status__changeStatus'><p>{statusList.LeadID === item.ID && statusList.opened ? 'Zatvori listu' : 'Promijeni status'}</p></div>
                      <div className={`${statusList.LeadID === item.ID && statusList.opened ? 'route__kanban__content__column__list__item__status__statusList route__kanban__content__column__list__item__status__statusList--active' : 'route__kanban__content__column__list__item__status__statusList'}`}>
                        <strong onClick={(e) => { changeStatus(item.ID, 0, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(19, 33, 60)' }}>Novi upiti</strong>
                        
                        <strong onClick={(e) => { changeStatus(item.ID, 2, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(239, 149, 0)' }}>U isporuci</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 3, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgba(33, 163, 9, 0.86)' }}>Naplaćeno</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 4, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgba(233, 43, 43, 0.88)' }}>Odbijeno</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 5, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(76, 56, 124)' }}>Pozvati ponovo</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 6, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(143, 88, 88)' }}>Loš broj</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 7, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(253, 94, 115)' }}>Ne javlja se</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 9, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(213 53 74)' }}>Ne javlja se x4</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 10, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(161 20 39)' }}>Ne javlja se x6</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 11, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(89 0 12)' }}>Zadnja šansa</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 12, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(77 0 70)' }}>Smeće</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 8, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(97 97 97)' }}>PJK</strong>
                      </div>
                    </div>}
                    {secSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
                  </div>
                }
              })
            }
            {paginationSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
            {dataNa?.data?.length >= 20 && <div className='route__kanban__content__column__list__paginationButton' onClick={() => getNext20(9, dataNa?.data?.length)}>
              <p>Učitaj još...</p>
            </div>}
          </div>
        </div>
        <div className='route__kanban__content__column'>
          <p style={{ background: 'rgb(77, 0, 70)' }}>Smeće </p>
          <div className='route__kanban__content__column__list'>
            {
              dataTrash?.data?.map((item) => {
                if (item.Status === 12) {
                  return <div className='route__kanban__content__column__list__item'>
                    <div onClick={(e) => animateBox(e, <LeadInfo user={props.userData} getAll={getData} status={12} lead={item} agents={agents} />)}>
                      <p>ID: #{item.ID}</p>
                      <p>Ime: {item.Name}</p>
                      <p>Proizvod: {item.Product}</p>
                      <p>Država: {item.Country}</p>
                      <p>Oznaka: {item.Tag}</p>
                      <p>Količina: {item.Quantity}</p>
                      <p>Cijena: {item.Price} {item.Country === 'RS' ? 'RSD' : 'KM'}</p>
                      <p>Pristiglo: {moment(item.createdAt).format('DD.MM.YYYY hh:mm:ss A')}</p>
                    </div>
                    {!secSpinner && <div className='route__kanban__content__column__list__item__status'>
                      <div onClick={() => openStatusList(item.ID)} className='route__kanban__content__column__list__item__status__changeStatus'><p>{statusList.LeadID === item.ID && statusList.opened ? 'Zatvori listu' : 'Promijeni status'}</p></div>
                      <div className={`${statusList.LeadID === item.ID && statusList.opened ? 'route__kanban__content__column__list__item__status__statusList route__kanban__content__column__list__item__status__statusList--active' : 'route__kanban__content__column__list__item__status__statusList'}`}>
                        <strong onClick={(e) => { changeStatus(item.ID, 0, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(19, 33, 60)' }}>Novi upiti</strong>
                        
                        <strong onClick={(e) => { changeStatus(item.ID, 2, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(239, 149, 0)' }}>U isporuci</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 3, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgba(33, 163, 9, 0.86)' }}>Naplaćeno</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 4, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgba(233, 43, 43, 0.88)' }}>Odbijeno</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 5, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(76, 56, 124)' }}>Pozvati ponovo</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 6, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(143, 88, 88)' }}>Loš broj</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 7, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(253, 94, 115)' }}>Ne javlja se</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 9, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(213 53 74)' }}>Ne javlja se x4</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 10, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(161 20 39)' }}>Ne javlja se x6</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 11, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(89 0 12)' }}>Zadnja šansa</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 12, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(77 0 70)' }}>Smeće</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 8, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(97 97 97)' }}>PJK</strong>
                      </div>
                    </div>}
                    {secSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
                  </div>
                }
              })
            }
            {paginationSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
            {dataNa?.data?.length >= 20 && <div className='route__kanban__content__column__list__paginationButton' onClick={() => getNext20(9, dataNa?.data?.length)}>
              <p>Učitaj još...</p>
            </div>}
          </div>
        </div>
      </div>}
      {
        selectedFilter.value >= 0 && <div className='route__kanban__content'>
          <div className='route__kanban__content__column'>
            <p style={{ background: '#F3F3F3', color: 'rgb(19, 33, 60)' }}>Filterisano: {selectedFilter.name} ({data?.data?.length})</p>
            <div className='route__kanban__content__column__list'>
              {spinner ? <Spinner color='white' style={{ width: '34px' }} align='center' /> : null}
              {
                !spinner && selectedFilter.value >= 0 && data?.data?.map((item) => {
                  if (item.Status === selectedFilter.value) {
                    return <div className='route__kanban__content__column__list__item'>
                      <div onClick={(e) => animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />)}>
                        <p>ID: #{item.ID}</p>
                        <p>Ime: {item.Name}</p>
                        <p>Proizvod: {item.Product}</p>
                        <p>Država: {item.Country}</p>
                        <p>Oznaka: {item.Tag}</p>
                        <p>Količina: {item.Quantity}</p>
                        <p>Cijena: {item.Price} {item.Country === 'RS' ? 'RSD' : 'KM'}</p>
                        <p>Pristiglo: {moment(item.createdAt).format('DD.MM.YYYY hh:mm:ss A')}</p>
                      </div>
                      {!secSpinner && <div className='route__kanban__content__column__list__item__status'>
                        <div onClick={() => openStatusList(item.ID)} className='route__kanban__content__column__list__item__status__changeStatus'><p>{statusList.LeadID === item.ID && statusList.opened ? 'Zatvori listu' : 'Promijeni status'}</p></div>
                        <div className={`${statusList.LeadID === item.ID && statusList.opened ? 'route__kanban__content__column__list__item__status__statusList route__kanban__content__column__list__item__status__statusList--active' : 'route__kanban__content__column__list__item__status__statusList'}`}>
                          <strong onClick={(e) => { changeStatus(item.ID, 0, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(19, 33, 60)' }}>Novi upiti</strong>
                          
                          <strong onClick={(e) => { changeStatus(item.ID, 2, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(239, 149, 0)' }}>U isporuci</strong>
                          <strong onClick={(e) => { changeStatus(item.ID, 3, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgba(33, 163, 9, 0.86)' }}>Naplaćeno</strong>
                          <strong onClick={(e) => { changeStatus(item.ID, 4, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgba(233, 43, 43, 0.88)' }}>Odbijeno</strong>
                          <strong onClick={(e) => { changeStatus(item.ID, 5, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(76, 56, 124)' }}>Pozvati ponovo</strong>
                          <strong onClick={(e) => { changeStatus(item.ID, 6, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(143, 88, 88)' }}>Loš broj</strong>
                          <strong onClick={(e) => { changeStatus(item.ID, 7, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(253, 94, 115)' }}>Ne javlja se</strong>
                          <strong onClick={(e) => { changeStatus(item.ID, 9, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(213 53 74)' }}>Ne javlja se x4</strong>
                          <strong onClick={(e) => { changeStatus(item.ID, 10, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(161 20 39)' }}>Ne javlja se x6</strong>
                          <strong onClick={(e) => { changeStatus(item.ID, 11, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(89 0 12)' }}>Zadnja šansa</strong>
                          <strong onClick={(e) => { changeStatus(item.ID, 12, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(77 0 70)' }}>Smeće</strong>
                          <strong onClick={(e) => { changeStatus(item.ID, 8, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(97 97 97)' }}>PJK</strong>
                        </div>
                      </div>}
                      {secSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
                    </div>
                  }
                })
              }
            </div>
          </div>
        </div>
      }

      {
        !spinner && searchResult?.data ? <div className='route__kanban__content'>
          <div className='route__kanban__content__column'>
            <p style={{ background: 'rgb(133 133 133)' }}>Pretraženo: {searchInputRef.current.value ? searchInputRef.current.value : null}</p>
            <div className='route__kanban__content__column__list'>
              {spinner ? <Spinner color='white' style={{ width: '34px' }} align='center' /> : null}
              {
                searchResult?.data?.map((item) => {
                  return <div className='route__kanban__content__column__list__item'>
                    <div onClick={(e) => animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />)}>
                      <p>ID: #{item.ID}</p>
                      <p>Ime: {item.Name}</p>
                      <p>Broj: {item.Phone}</p>
                      <p>Proizvod: {item.Product}</p>
                      <p>Država: {item.Country}</p>
                      <p>Oznaka: {item.Tag}</p>
                      <p>LeadHash: {item.LeadHash}</p>
                      <p>Količina: {item.Quantity}</p>
                      <p>Cijena: {item.Price} {item.Country === 'RS' ? 'RSD' : 'KM'}</p>
                      <p>Pristiglo: {moment(item.createdAt).format('DD.MM.YYYY hh:mm:ss A')}</p>
                    </div>
                    <div className='route__kanban__content__column__list__item__curStatus'>
                      <p>Trenutni status:</p>
                      {(() => {
                        switch (item.Status) {
                          case 0:
                            return <p>Novi upiti</p>
                            break;
                          case 1:
                            return <p>Potvrđeno</p>
                            break;
                          case 2:
                            return <p>U isporuci</p>
                            break;
                          case 3:
                            return <p>Naplaćeno</p>
                            break;
                          case 4:
                            return <p>Odbijeno</p>
                            break;
                          case 5:
                            return <p>Pozvati ponovo</p>
                            break;
                          case 6:
                            return <p>Loš broj</p>
                            break;
                          case 7:
                            return <p>Ne javlja se</p>
                            break;
                          case 8:
                            return <p>PJK</p>
                            break;
                        }
                      })()}
                    </div>
                    {!secSpinner && <div className='route__kanban__content__column__list__item__status'>
                      <div onClick={() => openStatusList(item.ID)} className='route__kanban__content__column__list__item__status__changeStatus'><p>{statusList.LeadID === item.ID && statusList.opened ? 'Zatvori listu' : 'Promijeni status'}</p></div>
                      <div className={`${statusList.LeadID === item.ID && statusList.opened ? 'route__kanban__content__column__list__item__status__statusList route__kanban__content__column__list__item__status__statusList--active' : 'route__kanban__content__column__list__item__status__statusList'}`}>
                        <strong onClick={(e) => { changeStatus(item.ID, 0, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(19, 33, 60)' }}>Novi upiti</strong>
                        
                        <strong onClick={(e) => { changeStatus(item.ID, 2, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(239, 149, 0)' }}>U isporuci</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 3, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgba(33, 163, 9, 0.86)' }}>Naplaćeno</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 4, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgba(233, 43, 43, 0.88)' }}>Odbijeno</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 5, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(76, 56, 124)' }}>Pozvati ponovo</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 6, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(143, 88, 88)' }}>Loš broj</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 7, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(253, 94, 115)' }}>Ne javlja se</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 9, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(213 53 74)' }}>Ne javlja se x4</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 10, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(161 20 39)' }}>Ne javlja se x6</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 11, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(89 0 12)' }}>Zadnja šansa</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 12, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(77 0 70)' }}>Smeće</strong>
                        <strong onClick={(e) => { changeStatus(item.ID, 8, item.Status); animateBox(e, <LeadInfo user={props.userData} getAll={getData} lead={item} agents={agents} />) }} style={{ background: 'rgb(97 97 97)' }}>PJK</strong>
                      </div>
                    </div>}
                    {secSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
                  </div>
                })
              }
            </div>
          </div>
        </div> : ''
      }
    </div>
  )
}

const LeadInfo = (props) => {
  const [data, setData] = React.useState([]);
  const [message, setMessage] = React.useState(null);
  const [spinner, setSpinner] = React.useState(true);
  const [btnSpinner, setBtnSpinner] = React.useState(false);
  const [secBtnSpinner, setSecBtnSpinner] = React.useState(false);
  const [thirdBtnSpinner, setThirdBtnSpinner] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [lastErrorMessage, setLastErrorMessage] = React.useState(null);
  const [selectedExDate, setSelectedExDate] = React.useState('');
  const [selectedCaDate, setSelectedCaDate] = React.useState('');
  const [scheduleSpinner, setScheduleSpinner] = React.useState(false);
  const [scheduleError, setScheduleError] = React.useState(null);
  const [scheduleSuccessMsg, setScheduleSuccessMsg] = React.useState(null);
  const [responsiblePerson, setResponsiblePerson] = React.useState('');
  const [responsiblePersonID, setResponsiblePersonID] = React.useState(null);
  const [step, setStep] = React.useState(1);
  const [scriptPath, setScriptPath] = React.useState(null);

  const commentRef = React.useRef(null);
  const nameRef = React.useRef(null);
  const phoneRef = React.useRef(null);
  const cityRef = React.useRef(null);
  const zipRef = React.useRef(null);
  const streetRef = React.useRef(null);
  const quantityOrderRef = React.useRef(null);
  const priceOrderRef = React.useRef(null);
  const noteRef = React.useRef(null);

  const getData = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leadActivity/getAllActivities`,
      data: {
        filters: [{ name: 'LeadID', op: 'eq', value: props?.lead?.ID }]
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setData(res.data);
      } else {
        return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch(() => {
      return setMessage('Server ne reagira...');
    }).finally(() => {
      setSpinner(false);
    });
  }

  const addActivity = () => {
    if (!commentRef.current.value) return setError('Sva polja moraju biti popunjena!');

    setBtnSpinner(true);

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leadActivity/addActivity`,
      data: {
        User: props.user?.FirstName,
        LeadID: props?.lead?.ID,
        Comment: commentRef.current.value,
        Quantity: 1,
        Price: 1
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        props.getAll();
        getData();
        commentRef.current.value = '';
        setError(null);
      } else {
        return setError('Došlo je do greške prilikom slanja podataka...');
      };
    }).catch(() => {
      return setError('Server ne reagira...');
    }).finally(() => {
      setBtnSpinner(false);
    });
  }

  const deleteLead = (status) => {
    setSecBtnSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/deleteLead`,
      data: {
        ID: props?.lead?.ID,
        token: "ikl3478345jdsfjkfgipucbvkld300xjoha"
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        props.getAll(status);
        getData();
        setError(null);
        props.onClose();
      } else {
        return setError('Došlo je do greške prilikom brisanja upita...');
      };
    }).catch(() => {
      return setError('Server ne reagira...');
    }).finally(() => {
      setSecBtnSpinner(false);
    });
  }

  const handleDateChangeEX = (event) => {
    setSelectedExDate(event.target.value);
  };

  const handleDateChangeCA = (event) => {
    setSelectedCaDate(event.target.value);
  };

  const scheduleCall = (type) => {
    if (!selectedExDate && !selectedCaDate) return;

    setScheduleSpinner(true);

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/extensions/addExtension`,
      data: {
        Type: type,
        LeadName: props?.lead?.Name,
        LeadProduct: props?.lead?.Product,
        LeadPhone: props?.lead?.Phone,
        LeadCountry: props?.lead?.Country,
        ScheduledDate: type === 'extension' ? selectedExDate : selectedCaDate
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        props.getAll();
        getData();
        setError(null);
        setScheduleSuccessMsg({ type: type, message: 'Uspješno ste zakazali poziv.' })
      } else {
        return setScheduleError('Došlo je do greške prilikom slanja podataka...');
      };
    }).catch(() => {
      return setScheduleError('Server ne reagira...');
    }).finally(() => {
      setScheduleSpinner(false);
    });
  }

  const getLeadByID = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getAllLeads`,
      data: {
        filters: [{ name: 'ID', op: 'eq', value: props.lead?.ID }],
        token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setResponsiblePersonID(res.data?.data[0]?.ResponsiblePerson);
      }
    }).catch(() => {

    }).finally(() => {

    });
  }

  const returnAgent = (ID) => {
    props.agents?.data?.map(agent => {
      if (agent.ID == ID) {
        return setResponsiblePerson(agent.FirstName + ' ' + agent.LastName);
      }
    })
  }

  const makeSell = () => {
    if (
      !nameRef.current.value ||
      !phoneRef.current.value ||
      !cityRef.current.value ||
      !streetRef.current.value ||
      !quantityOrderRef.current.value ||
      !priceOrderRef.current.value
    ) {
      return setLastErrorMessage('Popunite obavezna polja!');
    }

    setThirdBtnSpinner(true);

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/orders/addOrder`,
      data: {
        Token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
        Name: nameRef.current.value,
        Phone: phoneRef.current.value,
        City: cityRef.current.value,
        ZIP: zipRef.current.value,
        Street: streetRef.current.value,
        Quantity: quantityOrderRef.current.value,
        Price: priceOrderRef.current.value,
        Product: props.lead?.Product,
        LeadID: props.lead?.ID,
        OfferID: props.lead?.OfferID,
        PartnerID: props.lead?.PartnerID,
        Country: props.lead?.Country,
        Note: noteRef.current.value,
        Type: props.lead?.Type
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        props.getAll(0);
        props.getAll(1);
        props.getAll(2);
        props.getAll(3);
        props.getAll(4);
        props.getAll(5);
        props.getAll(6);
        props.getAll(7);
        props.getAll(8);
        props.getAll(9);
        props.getAll(10);
        props.getAll(11);
        props.getAll(12);
      }
    }).catch(() => {
      return setLastErrorMessage('Popunite obavezna polja!');
    }).finally(() => {
      setThirdBtnSpinner(false);
      props.onClose();
    });
  }

  React.useEffect(() => {
    getData();
    getLeadByID();
    offers.map(offer => {
      if (offer.ID === props.lead?.OfferID) {
        setScriptPath(offer.scriptPath);
      }
    })
  }, [props?.lead?.ID]);

  React.useEffect(() => {
    returnAgent(responsiblePersonID);
  }, [responsiblePersonID]);

  return <div className='route__kanban__leadInfo'>
    <div className='route__kanban__leadInfo__wrapper'>
      <div className='route__kanban__leadInfo__wrapper__header'>
        <h1>Aktivnost na upitu sa ID: #{props.lead.ID}</h1>
        {!props.user.Flags.isCCagent && <button onClick={() => deleteLead(props?.status)}>{!secBtnSpinner && 'OBRIŠI UPIT'}{secBtnSpinner && <Spinner color='white' align='center' style={{ width: '14px' }} />}</button>}
        <img onClick={props.onClose} src='images/head_close.svg' alt='' />
      </div>

      {step === 1 && <div className='route__kanban__leadInfo__wrapper__body'>
        <div className='route__kanban__leadInfo__wrapper__body__stepOne'>
          <p>ID: {props.lead?.ID}</p>
          <p>Ime: {props.lead?.Name}</p>
          <p>Broj: {props.lead?.Phone}</p>
          <p>Proizvod: {props.lead?.Product}</p>
          <p>Tip: {props.lead?.Type}</p>
          <p>Država: {props.lead?.Country}</p>
          <p>Oznaka: {props.lead?.Tag}</p>
          <p>LeadHash: {props.lead?.LeadHash}</p>
          {props.user.Flags.isCCmanager || props.user.Flags.isAdmin ? <p>Odgovorni agent: {responsiblePerson}</p> : null}
          <p>Zadnja obrada: {props.lead?.Processed ? moment(props.lead?.Processed).format('DD.MM.YYYY.') : 'Nije obrađen'}</p>
          <p>Publisher ID: {props.lead?.PublisherID}</p>
          <p>Partner ID: {props.lead?.PartnerID}</p>
          <p>Offer ID: {props.lead?.OfferID}</p>
          <p>Landing: {props.lead?.LandingLink}</p>
          <p>Pristiglo: {moment(props.lead?.createdAt).format('DD.MM.YYYY.')}</p>

          <div className='route__kanban__leadInfo__wrapper__body__stepOne__buttons'>
            {props.user.Flags.isCCmanager || props.user.Flags.isAdmin ? <div className='route__kanban__leadInfo__wrapper__body__responsible__button' onClick={(e) => animateBox(e, <AgentListModal refresh={getLeadByID} leadID={props.lead.ID} agents={props.agents} />)} ><p>Promijeni odgovornog agenta</p></div> : null}
            <div className='route__kanban__leadInfo__wrapper__body__responsible__button' onClick={() => setStep(2)} ><p>Dalje</p></div>
          </div>
        </div>
      </div>}

      {step === 2 && <div className='route__kanban__leadInfo__wrapper__body'>
        {scriptPath && <div style={{ height: '110vh' }}><Viewer theme='light' fileUrl={scriptPath} /></div>}
        <div className='route__kanban__leadInfo__wrapper__body__stepOne__buttons'>
          <div className='route__kanban__leadInfo__wrapper__body__responsible__button' onClick={() => setStep(1)} ><p>Nazad</p></div>
          <div className='route__kanban__leadInfo__wrapper__body__responsible__button' onClick={() => setStep(3)} ><p>Dalje</p></div>
        </div>
      </div>}

      {step === 3 && <div className='route__kanban__leadInfo__wrapper__body'>
        <div className='route__kanban__leadInfo__wrapper__body__input'>
          <textarea ref={commentRef} placeholder='Napiši komentar (ime, adresa, broj telefona...)'></textarea>
        </div>
        <div className='route__kanban__leadInfo__wrapper__body__quantity'>
          <button onClick={() => addActivity()}>{!btnSpinner ? 'Sačuvaj' : <Spinner align='center' color='white' style={{ width: '24px', height: '24px' }} />}</button>
        </div>
        {error && <p style={{ color: 'red', marginBottom: '10px' }}>{error}</p>}
        {
          !message && !spinner ? data?.data?.map(item => {
            return <div className='route__kanban__leadInfo__wrapper__body__item'>
              <strong>{item.User}</strong>
              <p>{item.Comment}</p>
            </div>
          }) : ''
        }
        <div className='route__kanban__leadInfo__wrapper__body__stepOne__buttons'>
          <div className='route__kanban__leadInfo__wrapper__body__responsible__button' onClick={() => setStep(2)} ><p>Nazad</p></div>
          {props.lead?.Status !== 1 ? <div className='route__kanban__leadInfo__wrapper__body__responsible__button' onClick={() => setStep(4)} ><p>Dalje</p></div> : null}
          {props.lead?.Status === 1 ? <div className='route__kanban__leadInfo__wrapper__body__responsible__button' onClick={(e) => animateBox(e, <EditPriceModal getAll={props.getAll} leadID={props.lead?.ID} />)} ><p>Ispravi cijenu</p></div> : null}
        </div>
        {message && !spinner ? <p style={{ color: 'red' }}>{message}</p> : ''}
        {spinner ? <Spinner style={{ width: '32px' }} color='rgb(19, 33, 60)' align='center' /> : ''}
      </div>}

      {step === 4 && props.lead?.Status !== 1 ? <div className='route__kanban__leadInfo__wrapper__body'>
        <div className='route__kanban__leadInfo__wrapper__body__stepFour'>
          <p className='route__kanban__leadInfo__wrapper__body__stepFour__note'>Ukoliko je ostvarena prodaja, popunite obrazac ispod, te zatim kliknite dugme "Zaključi prodaju", a ukoliko nije ostvarena prodaja, zatvorite ovaj prozor i promijenite status lead-a prema potrebi.</p>

          <div className='route__kanban__leadInfo__wrapper__body__stepFour__form'>
            <div className='route__kanban__leadInfo__wrapper__body__stepFour__form__field'>
              <label>Ime kupca</label>
              <input ref={nameRef} type='text' />
            </div>
            <div className='route__kanban__leadInfo__wrapper__body__stepFour__form__field'>
              <label>Broj telefona</label>
              <input ref={phoneRef} type='text' />
            </div>
            <div className='route__kanban__leadInfo__wrapper__body__stepFour__form__field'>
              <label>Grad</label>
              <input ref={cityRef} type='text' />
            </div>
            <div className='route__kanban__leadInfo__wrapper__body__stepFour__form__field'>
              <label>Poštanski broj (opcionalno)</label>
              <input ref={zipRef} type='text' />
            </div>
            <div className='route__kanban__leadInfo__wrapper__body__stepFour__form__field'>
              <label>Ulica</label>
              <input ref={streetRef} type='text' />
            </div>
            <div className='route__kanban__leadInfo__wrapper__body__stepFour__form__field'>
              <label>Količina</label>
              <input ref={quantityOrderRef} type='number' />
            </div>
            <div className='route__kanban__leadInfo__wrapper__body__stepFour__form__field'>
              <label>Cijena</label>
              <input ref={priceOrderRef} type='number' />
            </div>
            <div className='route__kanban__leadInfo__wrapper__body__stepFour__form__field'>
              <label>Napomena (opcionalno)</label>
              <input ref={noteRef} type='text' />
            </div>
          </div>

          <div className='route__kanban__leadInfo__wrapper__body__stepOne__buttons'>
            <div className='route__kanban__leadInfo__wrapper__body__responsible__button' onClick={() => setStep(3)} ><p>Nazad</p></div>
            <div className='route__kanban__leadInfo__wrapper__body__responsible__button' onClick={() => makeSell()} >
              {!thirdBtnSpinner && <p>Zaključi prodaju</p>}
              {thirdBtnSpinner && <Spinner color='white' style={{ width: '20px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />}
            </div>
          </div>
          {lastErrorMessage && <p style={{ background: '#991717', padding: '3px', color: 'white', width: '100%' }}>{lastErrorMessage}</p>}
        </div>
      </div> : null}

      <div className='route__kanban__leadInfo__wrapper__scheduleCall'>
        <div className='route__kanban__leadInfo__wrapper__scheduleCall__left'>
          <h1>Zakaži produženje</h1>
          <input type='date'
            value={selectedExDate}
            onChange={handleDateChangeEX}
          />
          <div onClick={() => scheduleCall('extension')} className='route__kanban__leadInfo__wrapper__scheduleCall__left__btn'>
            {
              !scheduleSpinner && scheduleError ? <p style={{ color: 'red' }}>{scheduleError}</p> : ''
            }
            {
              scheduleSpinner && !scheduleError ? <Spinner style={{ width: '14px' }} color='rgb(19, 33, 60)' /> : ''
            }
            {
              !scheduleSpinner && !scheduleError ? <p>Zakaži</p> : ''
            }
          </div>
          {scheduleSuccessMsg && scheduleSuccessMsg.type === 'extension' ? <p style={{ color: 'green' }}>{scheduleSuccessMsg.message}</p> : ''}
        </div>
        <div className='route__kanban__leadInfo__wrapper__scheduleCall__right'>
          <h1>Zakaži poziv</h1>
          <input type='date'
            value={selectedCaDate}
            onChange={handleDateChangeCA}
          />
          <div onClick={() => scheduleCall('callAgain')} className='route__kanban__leadInfo__wrapper__scheduleCall__right__btn'>
            {
              scheduleError ? <p style={{ color: 'red' }}>{scheduleError}</p> : ''
            }
            {
              scheduleSpinner ? <Spinner style={{ width: '14px' }} color='rgb(19, 33, 60)' /> : ''
            }
            {
              !scheduleSpinner && !scheduleError ? <p>Zakaži</p> : ''
            }
          </div>
          {scheduleSuccessMsg && scheduleSuccessMsg.type === 'callAgain' ? <p style={{ color: 'green' }}>{scheduleSuccessMsg.message}</p> : ''}
        </div>
      </div>
    </div>
  </div>
}

const EditPriceModal = (props) => {
  const [spinner, setSpinner] = React.useState(false);
  const [message, setMessage] = React.useState(null);

  const priceRef = React.useRef(null);

  const editPrice = () => {
    if(!priceRef.current.value) return setMessage('Popunite polje');
    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/editPrice`,
      data: {
        ID: props.leadID,
        NewPrice: priceRef.current.value
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        props.getAll(1);
        props.onClose();
      }
      else{
        setMessage('Došlo je do greške pri slanju podataka...');
      }
    }).catch(() => {
      setMessage('Server ne reagira...');
    }).finally(() => {
      setSpinner(false);
    });
  }

  return <div className='component__editPrice'>
  <div className='component__editPrice__wrapper'>
    <div className='component__editPrice__wrapper__header'>
      <h1 className='component__editPrice__wrapper__header__h1'>Ispravi cijenu</h1>
      <img onClick={props.onClose} src='images/head_close.svg' alt='' />
    </div>
    <div className='component__editPrice__wrapper__body'>
      <p>Unesi novu cijenu</p>
      <input ref={priceRef} type='number' />
      <div onClick={() => editPrice()} className='component__editPrice__wrapper__body__button'>
        {!spinner && <p>SAČUVAJ</p>}
        {spinner && <Spinner style={{ width: '22px' }} color='rgb(19, 33, 60)' />}
      </div>
      {message && <p style={{color: 'white', fontSize: '15px'}}>{message}</p>}
    </div>
  </div>
</div>
}

const ExstensionsModal = (props) => {
  const [error, setError] = React.useState(null);
  const [spinner, setSpinner] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [dataSpinner, setDataSpinner] = React.useState(true);

  const checkForTodayData = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/extensions/checkForTodayData`,
      data: {},
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        setData(res.data);
      }
    }).catch(() => {

    }).finally(() => {
      setDataSpinner(false);
    });
  }

  const removeItem = (ID) => {
    setSpinner(true);

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/extensions/removeExtension`,
      data: {
        ID: ID,
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        checkForTodayData();
      } else {
        return setError('Došlo je do greške prilikom brisanja upita...');
      };
    }).catch(() => {
      return setError('Server ne reagira...');
    }).finally(() => {
      setSpinner(false);
    });
  }

  React.useEffect(() => {
    checkForTodayData();
  }, []);

  return <div className='extensions'>
    <div className='extensions__wrapper'>
      <div className='extensions__wrapper__header'>
        <h1 className='extensions__wrapper__header__h1'>Produženja</h1>
        <img onClick={props.onClose} src='images/head_close.svg' alt='' />
      </div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <div className='extensions__wrapper__list'>
        {dataSpinner && <Spinner style={{ width: '34px' }} color='white' />}
        {
          !dataSpinner ? data?.data?.map((item) => {
            if (item.Type === 'extension') {
              return <div className='extensions__wrapper__list__lead'>
                {!spinner && <img onClick={() => removeItem(item.ID)} src='images/remove.png' alt='' />}
                {spinner && <Spinner style={{ width: '32px' }} color='white' />}
                <p>Ime: {item.LeadName}</p>
                <p>Broj: {item.LeadPhone}</p>
                <p>Proizvod: {item.LeadProduct}</p>
                <p>Država: {item.LeadCountry}</p>
              </div>
            }
          }) : ''
        }
      </div>
    </div>
  </div>
}

const CallAgainModal = (props) => {
  const [error, setError] = React.useState(null);
  const [spinner, setSpinner] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [dataSpinner, setDataSpinner] = React.useState(true);

  const checkForTodayData = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/extensions/checkForTodayData`,
      data: {},
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        setData(res.data);
      }
    }).catch(() => {

    }).finally(() => {
      setDataSpinner(false);
    });
  }

  const removeItem = (ID) => {
    setSpinner(true);

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/extensions/removeExtension`,
      data: {
        ID: ID,
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        checkForTodayData();
      } else {
        return setError('Došlo je do greške prilikom brisanja upita...');
      };
    }).catch(() => {
      return setError('Server ne reagira...');
    }).finally(() => {
      setSpinner(false);
    });
  }

  React.useEffect(() => {
    checkForTodayData();
  }, []);

  return <div className='extensions'>
    <div className='extensions__wrapper'>
      <div className='extensions__wrapper__header'>
        <h1 className='extensions__wrapper__header__h1'>Pozvati danas</h1>
        <img onClick={props.onClose} src='images/head_close.svg' alt='' />
      </div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <div className='extensions__wrapper__list'>
        {dataSpinner && <Spinner style={{ width: '34px' }} color='white' />}
        {
          !dataSpinner ? data?.data?.map((item) => {
            if (item.Type === 'callAgain') {
              return <div className='extensions__wrapper__list__lead'>
                {!spinner && <img onClick={() => removeItem(item.ID)} src='images/remove.png' alt='' />}
                {spinner && <Spinner style={{ width: '32px' }} color='white' />}
                <p>Ime: {item.LeadName}</p>
                <p>Broj: {item.LeadPhone}</p>
                <p>Proizvod: {item.LeadProduct}</p>
                <p>Država: {item.LeadCountry}</p>
              </div>
            }
          }) : ''
        }
      </div>
    </div>
  </div>
}

const AgentListModal = (props) => {
  const changeResponsiblePerson = (agentID) => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/changeResponsiblePerson`,
      data: {
        ID: props.leadID,
        AgentID: agentID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        props.onClose();
        props.refresh();
      }
    }).catch(() => {

    });
  }

  return <div className='component__agentList'>
    <div className='component__agentList__wrapper'>
      <img onClick={props.onClose} src='images/head_close.svg' alt='' />
      {props?.agents?.data?.map(agent => {
        return <p onClick={() => changeResponsiblePerson(agent.ID)}>{agent.FirstName} {agent.LastName}</p>
      })}
    </div>
  </div>
}