import React from 'react';
import "./index.scss";
import Spinner from '../Spinner';
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { animateBox } from "../../modules/componentAnimation";
import moment from 'moment/moment';

export default function Post(props) {
    const [data, setData] = React.useState([]);
    const [spinner, setSpinner] = React.useState(true);
    const [lengthSpinner, setLengthSpinner] = React.useState(true);
    const [message, setMessage] = React.useState(null);
    const [country, setCounty] = React.useState('BA');
    const [lengths, setLengths] = React.useState(null);

    const getData = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/orders/getAllOrders`,
            data: {
                token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
                filters: [{name: 'Country', op: 'eq', value: country}]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data);
            }
            else {
                setMessage('Greška prilikom dohvatanja podataka...');
            }
        }).catch(() => {
            setMessage('Server ne reagira...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const getLengths = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/orders/getLengths`,
            data: {
                token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setLengths(res.data);
            }
            else {
                setMessage('Greška prilikom dohvatanja podataka...');
            }
        }).catch(() => {
            setMessage('Server ne reagira...');
        }).finally(() => {
            setLengthSpinner(false);
        });
    }

    React.useEffect(() => {
        getData();
        getLengths();
    }, [country]);

    return (
        <div className='route__post'>
            <div className='route__post__header'>
                <p>Pošta</p>
                <div className='route__post__header__filters'>
                    <p style={{background: country === 'BA' ? 'rgb(111, 119, 136)' : '', padding: '3px'}} onClick={() => setCounty('BA')}>BA</p>
                    <p style={{background: country === 'RS' ? 'rgb(111, 119, 136)' : '', padding: '3px'}} onClick={() => setCounty('RS')}>RS</p>
                </div>
            </div>

            {!spinner && !message ? <div className='route__post__body'>
                <div className='route__post__body__returnRate'>
                    {!lengthSpinner ? <p>POVRAT: {country === 'BA' ? isNaN(Number(lengths?.data?.BA?.ReturnRate).toFixed(0)) ? '0' : Number(lengths?.data?.BA?.ReturnRate).toFixed(0) : isNaN(Number(lengths?.data?.RS?.ReturnRate).toFixed(0)) ? '0' : Number(lengths?.data?.RS?.ReturnRate).toFixed(0)}%</p> : <Spinner color='white' style={{width: '30px', height: '30px'}} />}
                </div>
                <div className='route__post__body__column'>
                    <div className='route__post__body__column__header'>
                        <h1>Paketi na čekanju ({!lengthSpinner && country === 'BA' ? lengths?.data?.BA.Waiting : !lengthSpinner && country === 'RS' ? lengths?.data?.RS.Waiting : <Spinner color='rgb(19, 33, 60)' style={{width: '14px', height: '14px'}} />})</h1>
                    </div>
                    <div className='route__post__body__column__table'>
                        <div className='route__post__body__column__table__headings'>
                            <p>ID</p>
                            <p>Proizvod</p>
                            <p>Stigao</p>
                            <p>Država</p>
                        </div>
                        <div className='route__post__body__column__table__content'>
                            {
                                data?.data?.map((order, index) => {
                                    if (order.Status === 0) {
                                        return <div onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode }, <OrderDetails isAdmin={props.isAdmin} calculateRR={getLengths} refresh={getData} orderID={order.ID} />)}  className='route__post__body__column__table__content__data'>
                                            <p>{order.ID}</p>
                                            <p>{order.Name}</p>
                                            <p>{moment(order.createdAt).format('DD.MM.YYYY.')}</p>
                                            <p>{order.Country}</p>
                                        </div>
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className='route__post__body__column'>
                    <div className='route__post__body__column__header'>
                        <h1>Poslani paketi ({!lengthSpinner && country === 'BA' ? lengths?.data?.BA.Sent : !lengthSpinner && country === 'RS' ? lengths?.data?.RS.Sent : <Spinner color='rgb(19, 33, 60)' style={{width: '14px', height: '14px'}} />})</h1>
                    </div>
                    <div className='route__post__body__column__table'>
                        <div className='route__post__body__column__table__headings'>
                            <p>ID</p>
                            <p>Proizvod</p>
                            <p>Poslan</p>
                            <p>Država</p>
                        </div>
                        <div className='route__post__body__column__table__content'>
                            {
                                data?.data?.map((order, index) => {
                                    if (order.Status === 1) {
                                        return <div onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode }, <OrderDetails isAdmin={props.isAdmin} calculateRR={getLengths} refresh={getData} orderID={order.ID} />)}  className='route__post__body__column__table__content__data'>
                                            <p>{order.ID}</p>
                                            <p>{order.Name}</p>
                                            <p>{moment(order.updatedAt).format('DD.MM.YYYY.')}</p>
                                            <p>{order.Country}</p>
                                        </div>
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className='route__post__body__column'>
                    <div className='route__post__body__column__header'>
                        <h1>Vraćeni paketi ({!lengthSpinner && country === 'BA' ? lengths?.data?.BA.Returned : !lengthSpinner && country === 'RS' ? lengths?.data?.RS.Returned : <Spinner color='rgb(19, 33, 60)' style={{width: '14px', height: '14px'}} />})</h1>
                    </div>
                    <div className='route__post__body__column__table'>
                        <div className='route__post__body__column__table__headings'>
                            <p>ID</p>
                            <p>Proizvod</p>
                            <p>Vraćen</p>
                            <p>Država</p>
                        </div>
                        <div className='route__post__body__column__table__content'>
                            {
                                data?.data?.map((order, index) => {
                                    if (order.Status === 2) {
                                        return <div onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode }, <OrderDetails isAdmin={props.isAdmin} calculateRR={getLengths} refresh={getData} orderID={order.ID} />)}  className='route__post__body__column__table__content__data'>
                                            <p>{order.ID}</p>
                                            <p>{order.Name}</p>
                                            <p>{moment(order.updatedAt).format('DD.MM.YYYY.')}</p>
                                            <p>{order.Country}</p>
                                        </div>
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
            </div> : null}
            {spinner && <Spinner color='rgb(19, 33, 60)' style={{ width: '42px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />}
            {message && <p style={{ color: 'white' }}>{message}</p>}
        </div>
    )
}


const OrderDetails = (props) => {
    const [data, setData] = React.useState([]);
    const [spinner, setSpinner] = React.useState(true);
    const [btnSpinner, setBtnSpinner] = React.useState(false);
    const [sendSpinner, setSendSpinner] = React.useState(false);
    const [message, setMessage] = React.useState(null);

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/orders/getAllOrders`,
            data: {
                token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
                filters: [{ name: 'ID', op: 'eq', value: props.orderID }]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data);
            }
            else {
                setMessage('Greška prilikom dohvatanja podataka...');
            }
        }).catch(() => {
            setMessage('Server ne reagira...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const deleteOrder = (ID) => {
        setBtnSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/orders/removeOrder`,
            data: {
                token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
                ID: ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.refresh();
            }
            else {
                setMessage('Greška prilikom dohvatanja podataka...');
            }
        }).catch(() => {
            setMessage('Server ne reagira...');
        }).finally(() => {
            setBtnSpinner(false);
            props.onClose();
        });
    }

    const changeStatus = (ID, Status, LeadID, Type) => {
        setSendSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/orders/changeStatus`,
            data: {
                ID: ID,
                Status: Status
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.refresh();
                props.calculateRR();
            }
            else {
                setMessage('Greška prilikom dohvatanja podataka...');
            }
        }).catch(() => {
            setMessage('Server ne reagira...');
        }).finally(() => {
            if(Type === 'return'){
                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/leads/changeStatus`,
                    data: {
                        ID: LeadID,
                        Status: 4
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") {
                        props.refresh();
                        props.calculateRR();
                    }
                    else {
                        setMessage('Greška prilikom dohvatanja podataka...');
                    }
                }).catch(() => {
                    setMessage('Server ne reagira...');
                }).finally(() => {
                    setSendSpinner(false);
                    props.onClose();
                });
            }
            else if(Type === 'pending'){
                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/leads/changeStatus`,
                    data: {
                        ID: LeadID,
                        Status: 1
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") {
                        props.refresh();
                        props.calculateRR();
                    }
                    else {
                        setMessage('Greška prilikom dohvatanja podataka...');
                    }
                }).catch(() => {
                    setMessage('Server ne reagira...');
                }).finally(() => {
                    setSendSpinner(false);
                    props.onClose();
                });
            }
            else{
                setSendSpinner(false);
                props.onClose();
            }
        });
    }

    React.useEffect(() => {
        getData();
    }, [props.orderID]);

    return <div className='component__detailsModal'>
        <div className='component__detailsModal__wrapper'>
            <div className='component__detailsModal__wrapper__header'>
                <h1 className='component__detailsModal__wrapper__header__h1'>Detalji paketa ID - {props.orderID}</h1>
                <img onClick={props.onClose} src='images/head_close.svg' alt='' />
            </div>

            {!spinner && !message ? <div className='component__detailsModal__wrapper__body'>
                <h1>Podaci za isporuku</h1>
                <div className='component__detailsModal__wrapper__body__block'>
                    <div className='component__detailsModal__wrapper__body__block__field'>
                        <label>Ime kupca</label>
                        <p>{data?.data[0]?.Name}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__block__field'>
                        <label>Broj telefona</label>
                        <p>{data?.data[0]?.Phone}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__block__field'>
                        <label>Grad</label>
                        <p>{data?.data[0]?.City}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__block__field'>
                        <label>Poštanski broj</label>
                        <p>{data?.data[0]?.ZIP}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__block__field'>
                        <label>Ulica</label>
                        <p>{data?.data[0]?.Street}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__block__field'>
                        <label>Količina</label>
                        <p>{data?.data[0]?.Quantity}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__block__field'>
                        <label>Cijena</label>
                        <p>{data?.data[0]?.Price}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__block__field'>
                        <label>Proizvod</label>
                        <p>{data?.data[0]?.Product}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__block__field'>
                        <label>Država</label>
                        <p>{data?.data[0]?.Country}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__block__field'>
                        <label>Napomena</label>
                        <p>{data?.data[0]?.Note}</p>
                    </div>
                </div>
                <h1>Ostali podaci</h1>
                <div className='component__detailsModal__wrapper__body__block'>
                    <p>Lead ID: {data?.data[0]?.LeadID}</p>
                    <p>Tip: {data?.data[0]?.Type}</p>
                    <p>Partner ID: {data?.data[0]?.PartnerID}</p>
                    <p>Status: {data?.data[0]?.Status === 0 ? 'Na čekanju' : data?.data[0]?.Status === 1 ? 'Paket poslan' : 'Paket vraćen'}</p>
                </div>
                <div className='component__detailsModal__wrapper__body__actions'>
                    {props.isAdmin && <div onClick={() => deleteOrder(data?.data[0]?.ID)} style={{ background: 'rgb(173, 7, 7)', color: '#F3F3F3' }} className='component__detailsModal__wrapper__body__actions__button'>
                        {!btnSpinner && <p>Obriši paket</p>}
                        {btnSpinner && <Spinner color='white' style={{width: '22px'}} />}
                    </div>}
                    {data?.data[0]?.Status === 0 && <div onClick={() => changeStatus(data?.data[0]?.ID, 1)} className='component__detailsModal__wrapper__body__actions__button'>
                        {!sendSpinner && <p>Pošalji paket</p>}
                        {sendSpinner && <Spinner color='rgb(19, 33, 60)' style={{width: '22px'}} />}
                    </div>}

                    {data?.data[0]?.Status === 1 && <div onClick={() => changeStatus(data?.data[0]?.ID, 2, data?.data[0]?.LeadID, 'return')} className='component__detailsModal__wrapper__body__actions__button'>
                        {!sendSpinner && <p>Paket vraćen</p>}
                        {sendSpinner && <Spinner color='rgb(19, 33, 60)' style={{width: '22px'}} />}
                    </div>}

                    {data?.data[0]?.Status === 2 && <div onClick={() => changeStatus(data?.data[0]?.ID, 0, data?.data[0]?.LeadID, 'pending')} className='component__detailsModal__wrapper__body__actions__button'>
                        {!sendSpinner && <p>Vrati na čekanje</p>}
                        {sendSpinner && <Spinner color='rgb(19, 33, 60)' style={{width: '22px'}} />}
                    </div>}
                </div>
            </div> : null}
            {spinner && <Spinner color='white' style={{ width: '42px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />}
            {message && <p style={{ color: 'white' }}>{message}</p>}
        </div>
    </div>
}