import React from 'react';
import "./index.css";

import Home from '../../components/Home';
import Statistics from '../../components/Statistics';
import Kanban from "../../components/Kanban";
import Users from "../../components/Users";
import Finances from '../../components/Finances';
import Publishers from '../../components/Publishers';
import AtWork from '../../components/AtWork';
import Post from '../../components/Post';
import Agents from '../../components/Agents';
import Spinner from '../../components/Spinner';

import axios, { all } from "axios";
import * as backendModule from "../../modules/backendModule";
import { useDispatch } from 'react-redux';
import * as timestampActions from "../../actions/timestampActions";

export default function AdminPanel(props) {
  const [activeTab, setActiveTab] = React.useState('home');
  const [nav, setNav] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [message, setMessage] = React.useState(null);
  const [spinner, setSpinner] = React.useState(true);
  const [appRate, setAppRate] = React.useState(0);
  const [todayAppRate, setTodayAppRate] = React.useState(0);
  const [dayData, setDayData] = React.useState([]);
  const [allLeads, setAllLeads] = React.useState([]);
  const [approvedLeads, setApprovedLeads] = React.useState([]);

  const curDispatch = useDispatch();

  const logout = () => {
    axios({
      method: "GET",
      url: `${backendModule.backendURL}/auth/logout`,
      data: {},
      ...backendModule.axiosConfig
    }).then(res => {
      curDispatch(timestampActions.updateTimestamp());
      window.location.reload();
    }).catch(() => {
    }).finally(() => {
    });
  }

  const getTodayProcessedLeads = () => {
    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getTodayProcessedLeads`,
      data: {
        filters: [],
        token: "ikl3478345jdsfjkfgipucbvkld300xjoha"
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setDayData(res.data);
        setSpinner(false);
      } else {
        return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch(() => {
      return setMessage('Server ne reagira...');
    });
  }

  const getAllLeads = () => {
    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getAllLeadsExcNew`,
      data: {
        filters: [],
        token: "ikl3478345jdsfjkfgipucbvkld300xjoha"
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setAllLeads(res.data);
        setSpinner(false);
      } else {
        return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch(() => {
      return setMessage('Server ne reagira...');
    });
  }

  const getApprovedLeads = () => {
    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getAllApprovedLeads`,
      data: {
        filters: [],
        token: "ikl3478345jdsfjkfgipucbvkld300xjoha"
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setApprovedLeads(res.data);
        setSpinner(false);
      } else {
        return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch(() => {
      return setMessage('Server ne reagira...');
    });
  }

  React.useEffect(() => {
    getTodayProcessedLeads();
    getAllLeads();
    getApprovedLeads();
  }, []);

  React.useEffect(() => {
    setAppRate((Number(approvedLeads?.data?.length) / Number(allLeads?.data?.length) * 100).toFixed(0));
  }, [allLeads, approvedLeads]);

  React.useEffect(() => {
    if (dayData?.data?.length > 0) {
      setTodayAppRate((Number(dayData?.data?.filter(e => e.Status === 1 || e.Status === 2 || e.Status === 3 || e.Status === 8).length) / Number(dayData?.data?.filter(e => e.Status != 0).length) * 100).toFixed(0))
    }
  }, [dayData]);

  return (
    <div className='route__adminPanel'>
      <div className='route__adminPanel__sidebar'>
        <div className='route__adminPanel__sidebar__logo'>
          <img src='images/logo.png' alt='' />
          <h1>RocketAd CRM</h1>
        </div>
        {!props.userData?.Flags?.isPost && <p className='route__adminPanel__sidebar__item' onClick={() => setActiveTab('kanban')} style={{ background: activeTab === 'kanban' ? 'rgb(111 119 136)' : '',  color: activeTab === 'kanban' ? '#13213C' : '' }}><img style={{filter: activeTab === 'kanban' ? 'invert(0)' : ''}} src='images/kanban.png' alt='' />Kanban</p>}
        {props.userData?.Flags?.isAdmin && <p className='route__adminPanel__sidebar__item' onClick={() => setActiveTab('statistics')} style={{ background: activeTab === 'statistics' ? 'rgb(111 119 136)' : '',  color: activeTab === 'statistics' ? '#13213C' : '' }}><img style={{filter: activeTab === 'statistics' ? 'invert(0)' : ''}} src='images/statistics.png' alt='' />Statistika</p>}
        {!props.userData?.Flags?.isCCagent && !props.userData?.Flags?.isCCmanager && !props.userData?.Flags?.isPost && <p className='route__adminPanel__sidebar__item' onClick={() => setActiveTab('finances')} style={{ background: activeTab === 'finances' ? 'rgb(111 119 136)' : '',  color: activeTab === 'finances' ? '#13213C' : '' }}><img style={{filter: activeTab === 'finances' ? 'invert(0)' : ''}} src='images/finances.png' alt='' />Finansije</p>}
        {!props.userData?.Flags?.isCCagent && !props.userData?.Flags?.isCCmanager && !props.userData?.Flags?.isPost && <p className='route__adminPanel__sidebar__item' onClick={() => setActiveTab('publishers')} style={{ background: activeTab === 'publishers' ? 'rgb(111 119 136)' : '',  color: activeTab === 'publishers' ? '#13213C' : '' }}><img style={{filter: activeTab === 'publishers' ? 'invert(0)' : ''}} src='images/publishers.png' alt='' />Partneri</p>}
        {props.userData?.Flags?.isAdmin || props.userData?.Flags?.isCCmanager  ? <p className='route__adminPanel__sidebar__item' onClick={() => setActiveTab('atwork')} style={{ background: activeTab === 'atwork' ? 'rgb(111 119 136)' : '',  color: activeTab === 'atwork' ? '#13213C' : '' }}><img style={{filter: activeTab === 'atwork' ? 'invert(0)' : ''}} src='images/atwork.png' alt='' />Smjena</p> : null}
        {props.userData?.Flags?.isAdmin || props.userData?.Flags?.isPost ? <p className='route__adminPanel__sidebar__item' onClick={() => setActiveTab('post')} style={{ background: activeTab === 'post' ? 'rgb(111 119 136)' : '',  color: activeTab === 'post' ? '#13213C' : '' }}><img style={{filter: activeTab === 'post' ? 'invert(0)' : ''}} src='images/post.png' alt='' />Pošta</p> : null}
        {props.userData?.Flags?.isAdmin && <p className='route__adminPanel__sidebar__item' onClick={() => setActiveTab('users')} style={{ background: activeTab === 'users' ? 'rgb(111 119 136)' : '',  color: activeTab === 'users' ? '#13213C' : '' }}><img style={{filter: activeTab === 'users' ? 'invert(0)' : ''}} src='images/users.png' alt='' />Korisnici</p>}
        {props.userData?.Flags?.isAdmin && <p className='route__adminPanel__sidebar__item' onClick={() => setActiveTab('agents')} style={{ background: activeTab === 'agents' ? 'rgb(111 119 136)' : '',  color: activeTab === 'agents' ? '#13213C' : '' }}><img style={{filter: activeTab === 'agents' ? 'invert(0)' : ''}} src='images/agents.png' alt='' />Agenti</p>}
        {!props.userData?.Flags?.isPost && <div className='route__adminPanel__sidebar__appRates'>
          <div className='route__adminPanel__sidebar__appRates__total'>
            <p>Vijek</p>
            {!spinner && <div className='route__adminPanel__sidebar__appRates__total__circle' style={{ border: appRate >= 35 ? '4px solid #29a329' : appRate >= 27 ? '4px solid #EF9500' : '4px solid #d33939' }}>
              <p>{appRate}%</p>
            </div>}
            {!spinner && <small>{Number(approvedLeads?.data?.length)} / {Number(allLeads?.data?.length)}</small>}
            {spinner && <Spinner color='#13213C' style={{ width: '30px' }} />}
          </div>
          <div className='route__adminPanel__sidebar__appRates__total'>
            <p>Danas</p>
            {!spinner && <div className='route__adminPanel__sidebar__appRates__total__circle' style={{ border: todayAppRate >= 35 ? '4px solid #29a329' : todayAppRate >= 27 ? '4px solid #EF9500' : '4px solid #d33939' }}>
              <p>{todayAppRate}%</p>
            </div>}
            {!spinner && <small>{Number(dayData?.data?.filter(e => e.Status === 1 || e.Status === 2 || e.Status === 3 || e.Status === 8).length)} / {Number(dayData?.data?.filter(e => e.Status != 0).length)}</small>}
            {spinner && <Spinner color='#13213C' style={{ width: '30px' }} />}
          </div>
        </div>}
        <strong onClick={() => logout()}> <img src='images/logout.png' alt='' /> Odjavi se</strong>
      </div>
      <div className='route__adminPanel__sidebar mobile'>
        <div className='route__adminPanel__sidebar__logo__mobile'>
          <img src='images/logo.png' alt='' />
          <h1>RocketAd CRM</h1>
        </div>
        <img onClick={() => setNav(n => !n)} src='images/hamburger.svg' alt='' />
        <div className={`route__adminPanel__sidebar mobile mob-nav ${nav ? 'route__adminPanel__sidebar mobile mob-nav mob-nav-active' : ''}`}>
        {!props.userData?.Flags?.isPost && <p className='route__adminPanel__sidebar__item' onClick={() => {setActiveTab('kanban'); setNav(false)}} style={{ background: activeTab === 'kanban' ? 'rgb(111 119 136)' : '',  color: activeTab === 'kanban' ? '#13213C' : '' }}><img style={{filter: activeTab === 'kanban' ? 'invert(0)' : ''}} src='images/kanban.png' alt='' />Kanban</p>}
        {props.userData?.Flags?.isAdmin && <p className='route__adminPanel__sidebar__item' onClick={() => {setActiveTab('statistics'); setNav(false)}} style={{ background: activeTab === 'statistics' ? 'rgb(111 119 136)' : '',  color: activeTab === 'statistics' ? '#13213C' : '' }}><img style={{filter: activeTab === 'statistics' ? 'invert(0)' : ''}} src='images/statistics.png' alt='' />Statistika</p>}
        {!props.userData?.Flags?.isCCagent && !props.userData?.Flags?.isCCmanager && !props.userData?.Flags?.isPost && <p className='route__adminPanel__sidebar__item' onClick={() => {setActiveTab('finances'); setNav(false)}} style={{ background: activeTab === 'finances' ? 'rgb(111 119 136)' : '',  color: activeTab === 'finances' ? '#13213C' : '' }}><img style={{filter: activeTab === 'finances' ? 'invert(0)' : ''}} src='images/finances.png' alt='' />Finansije</p>}
        {!props.userData?.Flags?.isCCagent && !props.userData?.Flags?.isCCmanager && !props.userData?.Flags?.isPost && <p className='route__adminPanel__sidebar__item' onClick={() => {setActiveTab('publishers'); setNav(false)}} style={{ background: activeTab === 'publishers' ? 'rgb(111 119 136)' : '',  color: activeTab === 'publishers' ? '#13213C' : '' }}><img style={{filter: activeTab === 'publishers' ? 'invert(0)' : ''}} src='images/publishers.png' alt='' />Partneri</p>}
        {props.userData?.Flags?.isAdmin || props.userData?.Flags?.isCCmanager ? <p className='route__adminPanel__sidebar__item' onClick={() => {setActiveTab('atwork'); setNav(false)}} style={{ background: activeTab === 'atwork' ? 'rgb(111 119 136)' : '',  color: activeTab === 'atwork' ? '#13213C' : '' }}><img style={{filter: activeTab === 'atwork' ? 'invert(0)' : ''}} src='images/atwork.png' alt='' />Smjena</p> : null}
        {props.userData?.Flags?.isAdmin || props.userData?.Flags?.isPost ? <p className='route__adminPanel__sidebar__item' onClick={() => {setActiveTab('post'); setNav(false)}} style={{ background: activeTab === 'post' ? 'rgb(111 119 136)' : '',  color: activeTab === 'post' ? '#13213C' : '' }}><img style={{filter: activeTab === 'post' ? 'invert(0)' : ''}} src='images/post.png' alt='' />Pošta</p> : null}
        {props.userData?.Flags?.isAdmin && <p className='route__adminPanel__sidebar__item' onClick={() => {setActiveTab('users'); setNav(false)}} style={{ background: activeTab === 'users' ? 'rgb(111 119 136)' : '',  color: activeTab === 'users' ? '#13213C' : '' }}><img style={{filter: activeTab === 'users' ? 'invert(0)' : ''}} src='images/users.png' alt='' />Korisnici</p>}
        {props.userData?.Flags?.isAdmin && <p className='route__adminPanel__sidebar__item' onClick={() => {setActiveTab('agents'); setNav(false)}} style={{ background: activeTab === 'agents' ? 'rgb(111 119 136)' : '',  color: activeTab === 'agents' ? '#13213C' : '' }}><img style={{filter: activeTab === 'agents' ? 'invert(0)' : ''}} src='images/agents.png' alt='' />Agenti</p>}
          <div className='route__adminPanel__sidebar__appRates'>
            <div className='route__adminPanel__sidebar__appRates__total'>
              <p>Vijek</p>
              {!spinner && <div className='route__adminPanel__sidebar__appRates__total__circle' style={{ border: appRate >= 35 ? '4px solid #29a329' : appRate >= 27 ? '4px solid #EF9500' : '4px solid #d33939' }}>
                <p>{appRate}%</p>
              </div>}
              {!spinner && <small>{Number(data?.data?.filter(e => e.Status === 1 || e.Status === 2 || e.Status === 3 || e.Status === 8).length)} / {Number(data?.data?.filter(e => e.Status != 0).length)}</small>}
              {spinner && <Spinner color='#13213C' style={{ width: '30px' }} />}
            </div>
            <div className='route__adminPanel__sidebar__appRates__total'>
              <p>Danas</p>
              {!spinner && <div className='route__adminPanel__sidebar__appRates__total__circle' style={{ border: todayAppRate >= 35 ? '4px solid #29a329' : todayAppRate >= 27 ? '4px solid #EF9500' : '4px solid #d33939' }}>
                <p>{todayAppRate}%</p>
              </div>}
              {!spinner && <small>{Number(dayData?.data?.filter(e => e.Status === 1 || e.Status === 2 || e.Status === 3 || e.Status === 8).length)} / {Number(dayData?.data?.filter(e => e.Status != 0).length)}</small>}
              {spinner && <Spinner color='#13213C' style={{ width: '30px' }} />}
            </div>
          </div>
          <strong onClick={() => logout()}>Odjavi se</strong>
        </div>
      </div>
      <div className='route__adminPanel__body'>
        {activeTab === 'home' && <Home userData={props.userData} />}
        {activeTab === 'kanban' && <Kanban userData={props.userData} />}
        {activeTab === 'users' && <Users />}
        {activeTab === 'finances' && <Finances isPostRS={props.userData?.Flags?.isPost} />}
        {activeTab === 'publishers' && <Publishers />}
        {activeTab === 'statistics' && <Statistics />}
        {activeTab === 'atwork' && <AtWork />}
        {activeTab === 'post' && <Post isAdmin={props.userData?.Flags?.isAdmin} />}
        {activeTab === 'agents' && <Agents />}
      </div>
    </div>
  )
}
