import React from 'react';
import "./index.scss";

export default function Home(props) {

  return (
    <div className='route__home'>
        <h1>Pozdrav {props.userData?.FirstName}!</h1>
        <p>{window.innerWidth < 550 ? 'Gore iz padajućeg izbornika odaberite šta to danas želite raditi u CRM-u.' : 'Sa lijeve strane odaberite šta to danas želite raditi u CRM-u.'}</p>
    </div>
  )
}
