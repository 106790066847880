import React from 'react';
import "./index.scss";
import Spinner from "../../components/Spinner";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { animateBox } from "../../modules/componentAnimation";

export default function Agents() {
    const [data, setData] = React.useState([]);
    const [spinner, setSpinner] = React.useState(true);
    const [message, setMessage] = React.useState(null);

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/agents/getAllAgents`,
            data: {},
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data);
            } else {
                return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
            };
        }).catch(() => {
            return setMessage('Server ne reagira...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    React.useEffect(() => {
        getData();
    }, []);

    return (
        <div className='route__agents'>
            <div className='route__agents__header'>
                <p>Agenti</p>
            </div>

            {!spinner && !message ? <div className='route__agents__body'>
                {
                    data?.data?.map(agent => {
                        return <div className='route__agents__body__agentBlock'>
                            <img src='images/agentProfile.webp' />
                            <div className='route__agents__body__agentBlock__info'>
                                <h1>{agent.AgentName}</h1>
                                <div className='route__agents__body__agentBlock__info__data'>
                                    <div>
                                        <p>AR Danas: {isNaN(agent.ARtoday) ? '0' : agent.ARtoday}%</p>
                                        <p>AR Jučer: {isNaN(agent.ARyesterday) ? '0' : agent.ARyesterday}%</p>
                                        <p>AR 7 dana: {isNaN(agent.ARsevenDays) ? '0' : agent.ARsevenDays}%</p>
                                        <p>AR Mjesec: {isNaN(agent.ARmonth) ? '0' : agent.ARmonth}%</p>
                                        <p>AR Vijek: {isNaN(agent.ARlifetime) ? '0' : agent.ARlifetime}%</p>
                                    </div>
                                    <div>
                                        <p>Prosječna prodaja danas: {isNaN(agent.AvgSellToday) ? '0' : agent.AvgSellToday} EUR</p>
                                        <p>Prosječna prodaja jučer: {isNaN(agent.AvgSellYesterday) ? '0' : agent.AvgSellYesterday} EUR</p>
                                        <p>Prosječna prodaja 7 dana: {isNaN(agent.AvgSellSevenDays) ? '0' : agent.AvgSellSevenDays} EUR</p>
                                        <p>Prosječna prodaja mjesec: {isNaN(agent.AvgSellMonth) ? '0' : agent.AvgSellMonth} EUR</p>
                                        <p>Prosječna prodaja vijek: {isNaN(agent.AvgSellTotal) ? '0' : agent.AvgSellTotal} EUR</p>
                                    </div>
                                    <div>
                                        <p>Promet danas: {isNaN(agent.TrafficToday) ? '0' : agent.TrafficToday} EUR</p>
                                        <p>Promet jučer: {isNaN(agent.TrafficYesterday) ? '0' : agent.TrafficYesterday} EUR</p>
                                        <p>Promet mjesec: {isNaN(agent.TrafficMonth) ? '0' : agent.TrafficMonth} EUR</p>
                                        <p>Obradio danas: {isNaN(agent.ProcessedToday) ? '0' : agent.ProcessedToday} upita</p>
                                        <p>Prodao danas: {isNaN(agent.SoldToday) ? '0' : agent.SoldToday} upita</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div> : null}
            {spinner && <Spinner style={{ width: '42px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#13213c' />}
            {message && <p style={{ color: '#9b0b0b' }}>{message}</p>}
        </div>
    )
}
