import Login from "./routes/Login";
import AdminPanel from "./routes/AdminPanel";
import React from 'react';
import axios from "axios";
import * as backendModule from "./modules/backendModule";
import Spinner from "./components/Spinner";
import { Worker } from '@react-pdf-viewer/core';

function App() {
  const [logged, setLogged] = React.useState(false);
  const [userData, setUserData] = React.useState([]);
  const [spinner, setSpinner] = React.useState(true);

  const checkLogin = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/auth/checkLogin`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setUserData(res.data.data.UserInfo);
        setLogged(true);
      }
      else {
        setLogged(false);
      }
    }).catch(() => {
      setLogged(false);
    }).finally(() => {
      setSpinner(false);
    });
  };

  React.useEffect(() => {
    checkLogin();
  }, []);

  return (
    <div className="route__contentWrapper">
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js"></Worker>
      {spinner && <Spinner color='gray' align='center' />}
      {!spinner && <div>
        {
          logged ?
            <AdminPanel userData={userData} />
            : <Login checkLogin={checkLogin} setLogged={setLogged} />
        }
      </div>}
    </div>
  );
}

export default App;