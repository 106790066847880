import React from 'react';
import "./index.scss";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import offers from "../../offers";
import Spinner from '../Spinner';
import { animateBox } from "../../modules/componentAnimation";
import moment from 'moment';

export default function Statistics() {
  const [message, setMessage] = React.useState(null);
  const [tableData, setTableData] = React.useState({
    total: { total: null, approved: null },
    month: { total: null, approved: null },
    sevenDays: { total: null, approved: null },
    yesterday: { total: null, approved: null },
    today: { total: null, approved: null }
  });
  const [tableSpinner, setTableSpinner] = React.useState(true);
  const [search, setSearch] = React.useState(null);
  const [profitData, setProfitData] = React.useState({
    month: 0,
    sevenDays: 0,
    yesterday: 0,
    today: 0
  });
  const [profitSpinner, setProfitSpinner] = React.useState(true);

  const searchRef = React.useRef(null);

  const getTableData = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getAllLeads`,
      data: {
        token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
        filters: [
          { name: 'Tag', op: 'eq', value: 'RocketAd' }
        ],
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setTableData(prev => {
          return {
            ...prev,
            total: { total: res.data?.data?.length, approved: res.data?.data?.filter(e => e.Status === 1 || e.Status === 8 || e.Status === 2 || e.Status === 3).length }
          }
        });
      } else {
        return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch(() => {
      return setMessage('Greška prilikom dohvatanja podataka...');
    }).finally(() => {
      axios({
        method: "POST",
        url: `${backendModule.backendURL}/leads/getMonthLeads`,
        data: {
          token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
          filters: [
            { name: 'Tag', op: 'eq', value: 'RocketAd' }
          ],
        },
        ...backendModule.axiosConfig
      }).then(res => {
        if (res.data.status === "ok") {
          setTableData(prev => {
            return {
              ...prev,
              month: { total: res.data?.data?.length, approved: res.data?.data?.filter(e => e.Status === 1 || e.Status === 8 || e.Status === 2 || e.Status === 3).length }
            }
          });
        } else {
          return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
        };
      }).catch(() => {
        return setMessage('Greška prilikom dohvatanja podataka...');
      }).finally(() => {
        axios({
          method: "POST",
          url: `${backendModule.backendURL}/leads/getSevenDaysLeads`,
          data: {
            token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
            filters: [
              { name: 'Tag', op: 'eq', value: 'RocketAd' }
            ],
          },
          ...backendModule.axiosConfig
        }).then(res => {
          if (res.data.status === "ok") {
            setTableData(prev => {
              return {
                ...prev,
                sevenDays: { total: res.data?.data?.length, approved: res.data?.data?.filter(e => e.Status === 1 || e.Status === 8 || e.Status === 2 || e.Status === 3).length }
              }
            });
          } else {
            return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
          };
        }).catch(() => {
          return setMessage('Greška prilikom dohvatanja podataka...');
        }).finally(() => {
          axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/getYesterdayLeads`,
            data: {
              token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
              filters: [
                { name: 'Tag', op: 'eq', value: 'RocketAd' }
              ],
            },
            ...backendModule.axiosConfig
          }).then(res => {
            if (res.data.status === "ok") {
              setTableData(prev => {
                return {
                  ...prev,
                  yesterday: { total: res.data?.data?.length, approved: res.data?.data?.filter(e => e.Status === 1 || e.Status === 8 || e.Status === 2 || e.Status === 3).length }
                }
              });
            } else {
              return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
            };
          }).catch(() => {
            return setMessage('Greška prilikom dohvatanja podataka...');
          }).finally(() => {
            axios({
              method: "POST",
              url: `${backendModule.backendURL}/leads/getTodaysLeads`,
              data: {
                token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
                filters: [
                  { name: 'Tag', op: 'eq', value: 'RocketAd' }
                ],
              },
              ...backendModule.axiosConfig
            }).then(res => {
              if (res.data.status === "ok") {
                setTableData(prev => {
                  return {
                    ...prev,
                    today: { total: res.data?.data?.length, approved: res.data?.data?.filter(e => e.Status === 1 || e.Status === 8 || e.Status === 2 || e.Status === 3).length }
                  }
                });
              } else {
                return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
              };
            }).catch(() => {
              return setMessage('Greška prilikom dohvatanja podataka...');
            }).finally(() => {
              setTableSpinner(false);
            });
          });
        });
      });
    });
  }

  const getProfitData = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getMonthLeads`,
      data: {
        token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
        filters: [
          { name: 'Status', op: 'eq', value: 1 },
        ],
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        let profit = 0;
        res.data?.data?.map(item => {
          if (item.Country === 'RS' || item.Country === 'rs') {
            profit = profit + ((Number(item.Price) / 60) - (Number(item.Quantity) * 2) - 42 - 9);
          }
          else if (item.Country === 'BA' || item.Country === 'ba') {
            profit = profit + (Number(item.Price) - (Number(item.Quantity) * 2) - 42 - 9);
          }
        });
        setProfitData(prev => {
          return {
            ...prev,
            month: profit,
            monthLength: res.data?.data?.length
          }
        });
      } else {
        return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch(() => {
      return setMessage('Greška prilikom dohvatanja podataka...');
    }).finally(() => {
      axios({
        method: "POST",
        url: `${backendModule.backendURL}/leads/getSevenDaysLeads`,
        data: {
          token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
          filters: [
            { name: 'Status', op: 'eq', value: 1 },
          ],
        },
        ...backendModule.axiosConfig
      }).then(res => {
        if (res.data.status === "ok") {
          let profit = 0;
          res.data?.data?.map(item => {
            if (item.Country === 'RS' || item.Country === 'rs') {
              profit = profit + ((Number(item.Price) / 60) - (Number(item.Quantity) * 2) - 42 - 9);
            }
            else if (item.Country === 'BA' || item.Country === 'ba') {
              profit = profit + (Number(item.Price) - (Number(item.Quantity) * 2) - 42 - 9);
            }
          });
          setProfitData(prev => {
            return {
              ...prev,
              sevenDays: profit,
              sevenDaysLength: res.data?.data?.length
            }
          });
        } else {
          return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
        };
      }).catch(() => {
        return setMessage('Greška prilikom dohvatanja podataka...');
      }).finally(() => {
        axios({
          method: "POST",
          url: `${backendModule.backendURL}/leads/getYesterdayLeads`,
          data: {
            token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
            filters: [
              { name: 'Status', op: 'eq', value: 1 },
            ],
          },
          ...backendModule.axiosConfig
        }).then(res => {
          if (res.data.status === "ok") {
            let profit = 0;
            res.data?.data?.map(item => {
              if (item.Country === 'RS' || item.Country === 'rs') {
                profit = profit + ((Number(item.Price) / 60) - (Number(item.Quantity) * 2) - 42 - 9);
              }
              else if (item.Country === 'BA' || item.Country === 'ba') {
                profit = profit + (Number(item.Price) - (Number(item.Quantity) * 2) - 42 - 9);
              }
            });
            setProfitData(prev => {
              return {
                ...prev,
                yesterday: profit,
                yesterdayLength: res.data?.data?.length
              }
            });
          } else {
            return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
          };
        }).catch(() => {
          return setMessage('Greška prilikom dohvatanja podataka...');
        }).finally(() => {
          axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/getTodaysLeads`,
            data: {
              token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
              filters: [
                { name: 'Status', op: 'eq', value: 1 },
              ],
            },
            ...backendModule.axiosConfig
          }).then(res => {
            if (res.data.status === "ok") {
              let profit = 0;
              res.data?.data?.map(item => {
                if (item.Country === 'RS' || item.Country === 'rs') {
                  profit = profit + ((Number(item.Price) / 60) - (Number(item.Quantity) * 2) - 42 - 9);
                }
                else if (item.Country === 'BA' || item.Country === 'ba'){
                  profit = profit + (Number(item.Price) - (Number(item.Quantity) * 2) - 42 - 9);
                }
              });
              setProfitData(prev => {
                return {
                  ...prev,
                  today: profit,
                  todayLength: res.data?.data?.length
                }
              });
            } else {
              return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
            };
          }).catch(() => {
            return setMessage('Greška prilikom dohvatanja podataka...');
          }).finally(() => {
            axios({
              method: "POST",
              url: `${backendModule.backendURL}/leads/getPreviousMonthLeads`,
              data: {
                token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
                filters: [
                  { name: 'Status', op: 'eq', value: 1 },
                ],
              },
              ...backendModule.axiosConfig
            }).then(res => {
              if (res.data.status === "ok") {
                let profit = 0;
                res.data?.data?.map((item, index) => {
                  if (item.Country === 'RS' || item.Country === 'rs') {
                    profit = profit + ((Number(item.Price) / 60) - (Number(item.Quantity) * 2) - 42 - 9);
                  }
                  else if (item.Country === 'BA' || item.Country === 'ba') {
                    profit = profit + (Number(item.Price) - (Number(item.Quantity) * 2) - 42 - 9);
                  }
                });
                setProfitData(prev => {
                  return {
                    ...prev,
                    prevMonth: profit,
                    prevMonthLength: res.data?.data?.length
                  }
                });
              } else {
                return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
              };
            }).catch(() => {
              return setMessage('Greška prilikom dohvatanja podataka...');
            }).finally(() => {
              axios({
                method: "POST",
                url: `${backendModule.backendURL}/leads/getTwoMonthsAgoLeads`,
                data: {
                  token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
                  filters: [
                    { name: 'Status', op: 'eq', value: 1 },
                  ],
                },
                ...backendModule.axiosConfig
              }).then(res => {
                if (res.data.status === "ok") {
                  let profit = 0;
                  res.data?.data?.map(item => {
                    if (item.Country === 'RS' || item.Country === 'rs') {
                      profit = profit + ((Number(item.Price) / 60) - (Number(item.Quantity) * 2) - 42 - 9);
                    }
                    else if (item.Country === 'BA' || item.Country === 'ba') {
                      profit = profit + (Number(item.Price) - (Number(item.Quantity) * 2) - 42 - 9);
                    }
                  });
                  setProfitData(prev => {
                    return {
                      ...prev,
                      twoAgoMonth: profit,
                      twoAgoMonthLength: res.data?.data?.length
                    }
                  });
                } else {
                  return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
                };
              }).catch(() => {
                return setMessage('Greška prilikom dohvatanja podataka...');
              }).finally(() => {
                setProfitSpinner(false);
              })
            })
          })
        })
      })
    })
  }

  React.useEffect(() => {
    getTableData();
    getProfitData();
  }, []);

  return (
    <div className='route__statistics'>
      <div className='route__statistics__header'>
        <p>Statistika</p>
      </div>

      <div className='route__statistics__wrapper'>
        {!profitSpinner && <div className='route__statistics__wrapper__rocketTable'>
          <div className='route__statistics__wrapper__rocketTable__title'>
            <h1>DOBIT</h1>
          </div>
          <div className='route__statistics__wrapper__rocketTable__header'>
            <strong>MJESEC (-2)</strong>
            <strong>MJESEC (-1)</strong>
            <strong>MJESEC</strong>
            <strong>7 DANA</strong>
            <strong>JUČER</strong>
            <strong>DANAS</strong>
          </div>
          <div className='route__statistics__wrapper__rocketTable__values'>
            <p>{Number(profitData.twoAgoMonth).toFixed(2)} KM</p>
            <p>{Number(profitData.prevMonth).toFixed(2)} KM</p>
            <p>{Number(profitData.month).toFixed(2)} KM</p>
            <p>{Number(profitData.sevenDays).toFixed(2)} KM</p>
            <p>{Number(profitData.yesterday).toFixed(2)} KM</p>
            <p>{Number(profitData.today).toFixed(2)} KM</p>
          </div>
          <div className='route__statistics__wrapper__rocketTable__values'>
            <p>{Number(profitData.twoAgoMonthLength)} L.</p>
            <p>{Number(profitData.prevMonthLength)} L.</p>
            <p>{Number(profitData.monthLength)} L.</p>
            <p>{Number(profitData.sevenDaysLength)} L.</p>
            <p>{Number(profitData.yesterdayLength)} L.</p>
            <p>{Number(profitData.todayLength)} L.</p>
          </div>
        </div>}
        {profitSpinner && <Spinner color='#13213C' style={{ width: "40px" }} />}

        {!tableSpinner && <div className='route__statistics__wrapper__rocketTable'>
          <div className='route__statistics__wrapper__rocketTable__title'>
            <h1>ROCKETAD UPITI</h1>
          </div>
          <div className='route__statistics__wrapper__rocketTable__header'>
            <strong>VIJEK</strong>
            <strong>MJESEC</strong>
            <strong>7 DANA</strong>
            <strong>JUČER</strong>
            <strong>DANAS</strong>
          </div>
          <div className='route__statistics__wrapper__rocketTable__values'>
            <p>{tableData?.total?.total} / {tableData?.total?.approved}</p>
            <p>{tableData?.month?.total} / {tableData?.month?.approved}</p>
            <p>{tableData?.sevenDays?.total} / {tableData?.sevenDays?.approved}</p>
            <p>{tableData?.yesterday?.total} / {tableData?.yesterday?.approved}</p>
            <p>{tableData?.today?.total} / {tableData?.today?.approved}</p>
          </div>
          <div className='route__statistics__wrapper__rocketTable__secondHeader'>
            <h2>APPROVE RATE</h2>
          </div>
          <div className='route__statistics__wrapper__rocketTable__values'>
            <p>{(Number(tableData?.total?.approved) / Number(tableData?.total?.total) * 100).toFixed(0)}%</p>
            <p>{(Number(tableData?.month?.approved) / Number(tableData?.month?.total) * 100).toFixed(0)}%</p>
            <p>{(Number(tableData?.sevenDays?.approved) / Number(tableData?.sevenDays?.total) * 100).toFixed(0)}%</p>
            <p>{(Number(tableData?.yesterday?.approved) / Number(tableData?.yesterday?.total) * 100).toFixed(0)}%</p>
            <p>{(Number(tableData?.today?.approved) / Number(tableData?.today?.total) * 100).toFixed(0)}%</p>
          </div>
        </div>}

        {tableSpinner && <Spinner color='#13213C' style={{ width: "40px" }} />}

        <div className='route__statistics__wrapper__products'>
          <div className='route__statistics__wrapper__products__header'>
            <h1>Proizvodi</h1>
            <div className='route__statistics__wrapper__products__header__search'>
              <input ref={searchRef} type='text' placeholder='Pretraži...' />
              <img onClick={() => setSearch(searchRef.current.value)} src='images/header__searchW.png' alt='' />
            </div>
          </div>
          {!search && <div className='route__statistics__wrapper__products__table'>
            {
              offers?.map(offer => {
                return <div className='route__statistics__wrapper__products__table__item' onClick={(e) => animateBox(e, <ProductModal offerID={offer.ID} offerName={offer.name} />)}>
                  <p>{offer.name} {offer.country}</p>
                </div>
              })
            }
          </div>}
          {search && <div className='route__statistics__wrapper__products__table'>
            {
              offers?.filter(itm => (itm.name).toLowerCase() === String(search).toLowerCase()).map(offer => {
                return <div className='route__statistics__wrapper__products__table__item' onClick={(e) => animateBox(e, <ProductModal offerID={offer.ID} offerName={offer.name} />)}>
                  <p>{offer.name} {offer.country}</p>
                </div>
              })
            }
          </div>}
        </div>
      </div>
    </div>
  )
}

const ProductModal = (props) => {
  const [spinner, setSpinner] = React.useState(true);
  const [secondSpinner, setSecondSpinner] = React.useState(true);
  const [thirdSpinner, setThirdSpinner] = React.useState(true);
  const [fourthSpinner, setFourthSpinner] = React.useState(true);
  const [fifthSpinner, setFifthSpinner] = React.useState(true);
  const [sixthSpinner, setSixthSpinner] = React.useState(true);
  const [message, setMessage] = React.useState(null);
  const [partners, setPartners] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [monthData, setMonthData] = React.useState([]);
  const [todaysData, setTodaysData] = React.useState([]);
  const [yesterdayData, setYesterdayData] = React.useState([]);
  const [sevenData, setSevenData] = React.useState([]);
  const [trash, setTrash] = React.useState(true);

  const getPartners = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/publishers/getAllPublishers`,
      data: {},
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setPartners(res.data);
      } else {
        return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch(() => {
      return setMessage('Greška prilikom dohvatanja podataka...');
    }).finally(() => {
      setSpinner(false);
    });
  }

  const getData = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getAllLeads`,
      data: {
        token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
        filters: [{ name: 'OfferID', op: 'eq', value: props.offerID }]
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setData(res.data);
      } else {
        return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch(() => {
      return setMessage('Server ne reagira...');
    }).finally(() => {
      setSecondSpinner(false);
    });
  }

  const getMonthData = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getMonthLeads`,
      data: {
        token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
        filters: [{ name: 'OfferID', op: 'eq', value: props.offerID }]
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setMonthData(res.data);
      } else {
        return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch(() => {
      return setMessage('Greška prilikom dohvatanja podataka...');
    }).finally(() => {
      setThirdSpinner(false);
    });
  }

  const getYesterdayData = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getYesterdayLeads`,
      data: {
        token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
        filters: [{ name: 'OfferID', op: 'eq', value: props.offerID }]
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setYesterdayData(res.data);
      } else {
        return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch(() => {
      return setMessage('Greška prilikom dohvatanja podataka...');
    }).finally(() => {
      setSixthSpinner(false);
    });
  }

  const getTodaysData = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getTodaysLeads`,
      data: {
        token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
        filters: [{ name: 'OfferID', op: 'eq', value: props.offerID }]
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setTodaysData(res.data);
      } else {
        return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch(() => {
      return setMessage('Greška prilikom dohvatanja podataka...');
    }).finally(() => {
      setFourthSpinner(false);
    });
  }

  const getSevenDaysData = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getSevenDaysLeads`,
      data: {
        token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
        filters: [{ name: 'OfferID', op: 'eq', value: props.offerID }]
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setSevenData(res.data);
      } else {
        return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch(() => {
      return setMessage('Greška prilikom dohvatanja podataka...');
    }).finally(() => {
      setFifthSpinner(false);
    });
  }

  React.useEffect(() => {
    getData();
    getMonthData();
    getTodaysData();
    getSevenDaysData();
    getYesterdayData();
    getPartners();
  }, []);

  return <div className='component__productModal'>
    <div className='component__productModal__wrapper'>
      <div className='component__productModal__wrapper__header'>
        <h1>{props.offerName}</h1>
        <img onClick={props.onClose} src='images/head_close.svg' alt='' />
      </div>
      <div className='component__productModal__wrapper__body'>
        <div className='component__productModal__wrapper__body__pubs'>
          {!spinner && partners?.data?.map(item => {
            return <p onClick={(e) => animateBox(e, <PartnerModal partnerID={item.Publisher.ID} offerID={props.offerID} partnerName={item.Publisher.Name} />)}>{item.Publisher.Name}</p>
          })}
        </div>
        {!spinner && !secondSpinner && !thirdSpinner && !fourthSpinner && !fifthSpinner && !sixthSpinner && !message ? <div style={{ marginTop: '10px' }} className='route__publishers__statsModal__wrapper__body__leads'>
          <div onClick={() => setTrash(n => !n)} className='route__publishers__statsModal__wrapper__body__btn'><p style={{ fontSize: '14px' }}>{trash ? 'Izbaci smeće' : 'Vrati smeće'}</p></div>
          <div className='route__publishers__statsModal__wrapper__body__leads__head'>
            <strong>Ukupno</strong>
            <strong>Mjesec</strong>
            <strong>7 dana</strong>
            <strong>Jučer</strong>
            <strong>Danas</strong>
          </div>
          {trash && <div className='route__publishers__statsModal__wrapper__body__leads__values'>
            <p>{data?.data?.length} / {(data?.data).filter(e => e.Status === 1).length}</p>
            <p>{monthData?.data?.length} / {monthData?.data?.filter(e => e.Status === 1).length}</p>
            <p>{sevenData?.data?.length} / {sevenData?.data?.filter(e => e.Status === 1).length}</p>
            <p>{yesterdayData?.data?.length} / {yesterdayData?.data?.filter(e => e.Status === 1).length}</p>
            <p>{todaysData?.data?.length} / {todaysData?.data?.filter(e => e.Status === 1).length}</p>
          </div>}
          {!trash && <div className='route__publishers__statsModal__wrapper__body__leads__values'>
            <p>{(data?.data).filter(obj => obj.Status !== 12).length} / {(data?.data).filter(e => e.Status === 1).length}</p>
            <p>{(monthData?.data).filter(obj => obj.Status !== 12)?.length} / {monthData?.data?.filter(e => e.Status === 1).length}</p>
            <p>{(sevenData?.data).filter(obj => obj.Status !== 12)?.length} / {sevenData?.data?.filter(e => e.Status === 1).length}</p>
            <p>{(yesterdayData?.data).filter(obj => obj.Status !== 12)?.length} / {yesterdayData?.data?.filter(e => e.Status === 1).length}</p>
            <p>{(todaysData?.data).filter(obj => obj.Status !== 12)?.length} / {todaysData?.data?.filter(e => e.Status === 1).length}</p>
          </div>}
          {trash && <div className='route__publishers__statsModal__wrapper__body__leads__values'>
            <p>{(Number(data?.data?.filter(e => e.Status === 1).length) / Number(data?.data.length) * 100).toFixed(0)}%</p>
            <p>{(Number(monthData?.data?.filter(e => e.Status === 1).length) / Number(monthData?.data.length) * 100).toFixed(0)}%</p>
            <p>{(Number(sevenData?.data?.filter(e => e.Status === 1).length) / Number(sevenData?.data.length) * 100).toFixed(0)}%</p>
            <p>{(Number(yesterdayData?.data?.filter(e => e.Status === 1).length) / Number(yesterdayData?.data.length) * 100).toFixed(0)}%</p>
            <p>{(Number(todaysData?.data?.filter(e => e.Status === 1).length) / Number(todaysData?.data.length) * 100).toFixed(0)}%</p>
          </div>}
          {!trash && <div className='route__publishers__statsModal__wrapper__body__leads__values'>
            <p>{(Number(data?.data?.filter(e => e.Status === 1).length) / Number((data?.data).filter(obj => obj.Status !== 12).length) * 100).toFixed(0)}%</p>
            <p>{(Number(monthData?.data?.filter(e => e.Status === 1).length) / Number((monthData?.data).filter(obj => obj.Status !== 12)?.length) * 100).toFixed(0)}%</p>
            <p>{(Number(sevenData?.data?.filter(e => e.Status === 1).length) / Number((sevenData?.data).filter(obj => obj.Status !== 12)?.length) * 100).toFixed(0)}%</p>
            <p>{(Number(yesterdayData?.data?.filter(e => e.Status === 1).length) / Number((yesterdayData?.data).filter(obj => obj.Status !== 12)?.length) * 100).toFixed(0)}%</p>
            <p>{(Number(todaysData?.data?.filter(e => e.Status === 1).length) / Number((todaysData?.data).filter(obj => obj.Status !== 12)?.length) * 100).toFixed(0)}%</p>
          </div>}
        </div> : null}

        {spinner || secondSpinner || thirdSpinner || fourthSpinner || fifthSpinner || sixthSpinner ? <Spinner style={{ width: '36px' }} color='white' /> : null}
        {message && <p style={{ color: "white" }}>{message}</p>}
      </div>
    </div>
  </div>
}

const PartnerModal = (props) => {
  const [secondSpinner, setSecondSpinner] = React.useState(true);
  const [thirdSpinner, setThirdSpinner] = React.useState(true);
  const [fourthSpinner, setFourthSpinner] = React.useState(true);
  const [fifthSpinner, setFifthSpinner] = React.useState(true);
  const [sixthSpinner, setSixthSpinner] = React.useState(true);
  const [message, setMessage] = React.useState(null);
  const [data, setData] = React.useState([]);
  const [monthData, setMonthData] = React.useState([]);
  const [todaysData, setTodaysData] = React.useState([]);
  const [yesterdayData, setYesterdayData] = React.useState([]);
  const [sevenData, setSevenData] = React.useState([]);
  const [trash, setTrash] = React.useState(true);

  const publisherIdRef = React.useRef(null);
  const webmasterIdRef = React.useRef(null);

  const getData = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getAllLeads`,
      data: {
        token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
        filters: [
          { name: 'OfferID', op: 'eq', value: props.offerID },
          { name: 'PartnerID', op: 'eq', value: props.partnerID }
        ]
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setData(res.data);
      } else {
        return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch(() => {
      return setMessage('Server ne reagira...');
    }).finally(() => {
      setSecondSpinner(false);
    });
  }

  const getMonthData = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getMonthLeads`,
      data: {
        token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
        filters: [
          { name: 'OfferID', op: 'eq', value: props.offerID },
          { name: 'PartnerID', op: 'eq', value: props.partnerID }
        ]
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setMonthData(res.data);
      } else {
        return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch(() => {
      return setMessage('Greška prilikom dohvatanja podataka...');
    }).finally(() => {
      setThirdSpinner(false);
    });
  }

  const getYesterdayData = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getYesterdayLeads`,
      data: {
        token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
        filters: [
          { name: 'OfferID', op: 'eq', value: props.offerID },
          { name: 'PartnerID', op: 'eq', value: props.partnerID }
        ]
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setYesterdayData(res.data);
      } else {
        return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch(() => {
      return setMessage('Greška prilikom dohvatanja podataka...');
    }).finally(() => {
      setSixthSpinner(false);
    });
  }

  const getTodaysData = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getTodaysLeads`,
      data: {
        token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
        filters: [
          { name: 'OfferID', op: 'eq', value: props.offerID },
          { name: 'PartnerID', op: 'eq', value: props.partnerID }
        ]
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setTodaysData(res.data);
      } else {
        return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch(() => {
      return setMessage('Greška prilikom dohvatanja podataka...');
    }).finally(() => {
      setFourthSpinner(false);
    });
  }

  const getSevenDaysData = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getSevenDaysLeads`,
      data: {
        token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
        filters: [
          { name: 'OfferID', op: 'eq', value: props.offerID },
          { name: 'PartnerID', op: 'eq', value: props.partnerID }
        ]
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setSevenData(res.data);
      } else {
        return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch(() => {
      return setMessage('Greška prilikom dohvatanja podataka...');
    }).finally(() => {
      setFifthSpinner(false);
    });
  }

  React.useEffect(() => {
    getData();
    getMonthData();
    getTodaysData();
    getSevenDaysData();
    getYesterdayData();
  }, []);

  return <div className='component__productModal'>
    <div className='component__productModal__wrapper'>
      <div className='component__productModal__wrapper__header'>
        <h1>{props.partnerName}</h1>
        <img onClick={props.onClose} src='images/head_close.svg' alt='' />
      </div>
      <div className='component__productModal__wrapper__body'>
        {!secondSpinner && !thirdSpinner && !fourthSpinner && !fifthSpinner && !sixthSpinner && !message ? <div style={{ marginTop: '10px' }} className='route__publishers__statsModal__wrapper__body__leads'>
          <div onClick={() => setTrash(n => !n)} className='route__publishers__statsModal__wrapper__body__btn'><p style={{ fontSize: '14px' }}>{trash ? 'Izbaci smeće' : 'Vrati smeće'}</p></div>
          <div className='route__publishers__statsModal__wrapper__body__leads__head'>
            <strong>Ukupno</strong>
            <strong>Mjesec</strong>
            <strong>7 dana</strong>
            <strong>Jučer</strong>
            <strong>Danas</strong>
          </div>
          {trash && <div className='route__publishers__statsModal__wrapper__body__leads__values'>
            <p>{data?.data?.length} / {(data?.data).filter(e => e.Status === 1).length}</p>
            <p>{monthData?.data?.length} / {monthData?.data?.filter(e => e.Status === 1).length}</p>
            <p>{sevenData?.data?.length} / {sevenData?.data?.filter(e => e.Status === 1).length}</p>
            <p>{yesterdayData?.data?.length} / {yesterdayData?.data?.filter(e => e.Status === 1).length}</p>
            <p>{todaysData?.data?.length} / {todaysData?.data?.filter(e => e.Status === 1).length}</p>
          </div>}
          {!trash && <div className='route__publishers__statsModal__wrapper__body__leads__values'>
            <p>{(data?.data).filter(obj => obj.Status !== 12).length} / {(data?.data).filter(e => e.Status === 1).length}</p>
            <p>{(monthData?.data).filter(obj => obj.Status !== 12)?.length} / {monthData?.data?.filter(e => e.Status === 1).length}</p>
            <p>{(sevenData?.data).filter(obj => obj.Status !== 12)?.length} / {sevenData?.data?.filter(e => e.Status === 1).length}</p>
            <p>{(yesterdayData?.data).filter(obj => obj.Status !== 12)?.length} / {yesterdayData?.data?.filter(e => e.Status === 1).length}</p>
            <p>{(todaysData?.data).filter(obj => obj.Status !== 12)?.length} / {todaysData?.data?.filter(e => e.Status === 1).length}</p>
          </div>}
          {trash && <div className='route__publishers__statsModal__wrapper__body__leads__values'>
            <p>{(Number(data?.data?.filter(e => e.Status === 1).length) / Number(data?.data.length) * 100).toFixed(0)}%</p>
            <p>{(Number(monthData?.data?.filter(e => e.Status === 1).length) / Number(monthData?.data.length) * 100).toFixed(0)}%</p>
            <p>{(Number(sevenData?.data?.filter(e => e.Status === 1).length) / Number(sevenData?.data.length) * 100).toFixed(0)}%</p>
            <p>{(Number(yesterdayData?.data?.filter(e => e.Status === 1).length) / Number(yesterdayData?.data.length) * 100).toFixed(0)}%</p>
            <p>{(Number(todaysData?.data?.filter(e => e.Status === 1).length) / Number(todaysData?.data.length) * 100).toFixed(0)}%</p>
          </div>}
          {!trash && <div className='route__publishers__statsModal__wrapper__body__leads__values'>
            <p>{(Number(data?.data?.filter(e => e.Status === 1).length) / Number((data?.data).filter(obj => obj.Status !== 12).length) * 100).toFixed(0)}%</p>
            <p>{(Number(monthData?.data?.filter(e => e.Status === 1).length) / Number((monthData?.data).filter(obj => obj.Status !== 12)?.length) * 100).toFixed(0)}%</p>
            <p>{(Number(sevenData?.data?.filter(e => e.Status === 1).length) / Number((sevenData?.data).filter(obj => obj.Status !== 12)?.length) * 100).toFixed(0)}%</p>
            <p>{(Number(yesterdayData?.data?.filter(e => e.Status === 1).length) / Number((yesterdayData?.data).filter(obj => obj.Status !== 12)?.length) * 100).toFixed(0)}%</p>
            <p>{(Number(todaysData?.data?.filter(e => e.Status === 1).length) / Number((todaysData?.data).filter(obj => obj.Status !== 12)?.length) * 100).toFixed(0)}%</p>
          </div>}
        </div> : null}

        {secondSpinner || thirdSpinner || fourthSpinner || fifthSpinner || sixthSpinner ? <Spinner style={{ width: '36px' }} color='white' /> : null}
        {message && <p style={{ color: "white" }}>{message}</p>}

        <div className='component__productModal__wrapper__body__buttons'>
          <div className='component__productModal__wrapper__body__buttons__block'>
            <p>PublisherID</p>
            <input ref={publisherIdRef} type='text' placeholder='Upiši ID' />
            <div className='component__productModal__wrapper__body__buttons__block__btn' onClick={(e) => animateBox(e, <PubWebModal offerID={props.offerID} partnerID={props.partnerID} publisherID={publisherIdRef.current.value} />)}>Obradi</div>
          </div>
          <div className='component__productModal__wrapper__body__buttons__block'>
            <p>WebmasterID</p>
            <input ref={webmasterIdRef} type='text' placeholder='Upiši ID' />
            <div className='component__productModal__wrapper__body__buttons__block__btn' onClick={(e) => animateBox(e, <PubWebModal offerID={props.offerID} partnerID={props.partnerID} webmasterID={webmasterIdRef.current.value} />)}>Obradi</div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

const PubWebModal = (props) => {
  const [secondSpinner, setSecondSpinner] = React.useState(true);
  const [thirdSpinner, setThirdSpinner] = React.useState(true);
  const [fourthSpinner, setFourthSpinner] = React.useState(true);
  const [fifthSpinner, setFifthSpinner] = React.useState(true);
  const [sixthSpinner, setSixthSpinner] = React.useState(true);
  const [message, setMessage] = React.useState(null);
  const [data, setData] = React.useState([]);
  const [monthData, setMonthData] = React.useState([]);
  const [todaysData, setTodaysData] = React.useState([]);
  const [yesterdayData, setYesterdayData] = React.useState([]);
  const [sevenData, setSevenData] = React.useState([]);
  const [trash, setTrash] = React.useState(true);

  const getData = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getAllLeads`,
      data: {
        token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
        filters: [
          { name: 'OfferID', op: 'eq', value: props.offerID },
          { name: 'PartnerID', op: 'eq', value: props.partnerID },
          { name: 'PublisherID', op: 'eq', value: props.publisherID },
          { name: 'WebmasterID', op: 'eq', value: props.webmasterID }
        ]
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setData(res.data);
      } else {
        return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch(() => {
      return setMessage('Server ne reagira...');
    }).finally(() => {
      setSecondSpinner(false);
    });
  }

  const getMonthData = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getMonthLeads`,
      data: {
        token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
        filters: [
          { name: 'OfferID', op: 'eq', value: props.offerID },
          { name: 'PartnerID', op: 'eq', value: props.partnerID },
          { name: 'PublisherID', op: 'eq', value: props.publisherID },
          { name: 'WebmasterID', op: 'eq', value: props.webmasterID }
        ]
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setMonthData(res.data);
      } else {
        return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch(() => {
      return setMessage('Greška prilikom dohvatanja podataka...');
    }).finally(() => {
      setThirdSpinner(false);
    });
  }

  const getYesterdayData = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getYesterdayLeads`,
      data: {
        token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
        filters: [
          { name: 'OfferID', op: 'eq', value: props.offerID },
          { name: 'PartnerID', op: 'eq', value: props.partnerID },
          { name: 'PublisherID', op: 'eq', value: props.publisherID },
          { name: 'WebmasterID', op: 'eq', value: props.webmasterID }
        ]
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setYesterdayData(res.data);
      } else {
        return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch(() => {
      return setMessage('Greška prilikom dohvatanja podataka...');
    }).finally(() => {
      setSixthSpinner(false);
    });
  }

  const getTodaysData = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getTodaysLeads`,
      data: {
        token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
        filters: [
          { name: 'OfferID', op: 'eq', value: props.offerID },
          { name: 'PartnerID', op: 'eq', value: props.partnerID },
          { name: 'PublisherID', op: 'eq', value: props.publisherID },
          { name: 'WebmasterID', op: 'eq', value: props.webmasterID }
        ]
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setTodaysData(res.data);
      } else {
        return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch(() => {
      return setMessage('Greška prilikom dohvatanja podataka...');
    }).finally(() => {
      setFourthSpinner(false);
    });
  }

  const getSevenDaysData = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getSevenDaysLeads`,
      data: {
        token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
        filters: [
          { name: 'OfferID', op: 'eq', value: props.offerID },
          { name: 'PartnerID', op: 'eq', value: props.partnerID },
          { name: 'PublisherID', op: 'eq', value: props.publisherID },
          { name: 'WebmasterID', op: 'eq', value: props.webmasterID }
        ]
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setSevenData(res.data);
      } else {
        return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch(() => {
      return setMessage('Greška prilikom dohvatanja podataka...');
    }).finally(() => {
      setFifthSpinner(false);
    });
  }

  React.useEffect(() => {
    getData();
    getMonthData();
    getTodaysData();
    getSevenDaysData();
    getYesterdayData();
  }, []);

  return <div className='component__productModal'>
    <div className='component__productModal__wrapper'>
      <div className='component__productModal__wrapper__header'>
        <h1>{props.publisherID ? props.publisherID : props.webmasterID}</h1>
        <img onClick={props.onClose} src='images/head_close.svg' alt='' />
      </div>
      <div className='component__productModal__wrapper__body'>
        {!secondSpinner && !thirdSpinner && !fourthSpinner && !fifthSpinner && !sixthSpinner && !message ? <div style={{ marginTop: '10px' }} className='route__publishers__statsModal__wrapper__body__leads'>
          <div onClick={() => setTrash(n => !n)} className='route__publishers__statsModal__wrapper__body__btn'><p style={{ fontSize: '14px' }}>{trash ? 'Izbaci smeće' : 'Vrati smeće'}</p></div>
          <div className='route__publishers__statsModal__wrapper__body__leads__head'>
            <strong>Ukupno</strong>
            <strong>Mjesec</strong>
            <strong>7 dana</strong>
            <strong>Jučer</strong>
            <strong>Danas</strong>
          </div>
          {trash && <div className='route__publishers__statsModal__wrapper__body__leads__values'>
            <p>{data?.data?.length} / {(data?.data).filter(e => e.Status === 1).length}</p>
            <p>{monthData?.data?.length} / {monthData?.data?.filter(e => e.Status === 1).length}</p>
            <p>{sevenData?.data?.length} / {sevenData?.data?.filter(e => e.Status === 1).length}</p>
            <p>{yesterdayData?.data?.length} / {yesterdayData?.data?.filter(e => e.Status === 1).length}</p>
            <p>{todaysData?.data?.length} / {todaysData?.data?.filter(e => e.Status === 1).length}</p>
          </div>}
          {!trash && <div className='route__publishers__statsModal__wrapper__body__leads__values'>
            <p>{(data?.data).filter(obj => obj.Status !== 12).length} / {(data?.data).filter(e => e.Status === 1).length}</p>
            <p>{(monthData?.data).filter(obj => obj.Status !== 12)?.length} / {monthData?.data?.filter(e => e.Status === 1).length}</p>
            <p>{(sevenData?.data).filter(obj => obj.Status !== 12)?.length} / {sevenData?.data?.filter(e => e.Status === 1).length}</p>
            <p>{(yesterdayData?.data).filter(obj => obj.Status !== 12)?.length} / {yesterdayData?.data?.filter(e => e.Status === 1).length}</p>
            <p>{(todaysData?.data).filter(obj => obj.Status !== 12)?.length} / {todaysData?.data?.filter(e => e.Status === 1).length}</p>
          </div>}
          {trash && <div className='route__publishers__statsModal__wrapper__body__leads__values'>
            <p>{(Number(data?.data?.filter(e => e.Status === 1).length) / Number(data?.data.length) * 100).toFixed(0)}%</p>
            <p>{(Number(monthData?.data?.filter(e => e.Status === 1).length) / Number(monthData?.data.length) * 100).toFixed(0)}%</p>
            <p>{(Number(sevenData?.data?.filter(e => e.Status === 1).length) / Number(sevenData?.data.length) * 100).toFixed(0)}%</p>
            <p>{(Number(yesterdayData?.data?.filter(e => e.Status === 1).length) / Number(yesterdayData?.data.length) * 100).toFixed(0)}%</p>
            <p>{(Number(todaysData?.data?.filter(e => e.Status === 1).length) / Number(todaysData?.data.length) * 100).toFixed(0)}%</p>
          </div>}
          {!trash && <div className='route__publishers__statsModal__wrapper__body__leads__values'>
            <p>{(Number(data?.data?.filter(e => e.Status === 1).length) / Number((data?.data).filter(obj => obj.Status !== 12).length) * 100).toFixed(0)}%</p>
            <p>{(Number(monthData?.data?.filter(e => e.Status === 1).length) / Number((monthData?.data).filter(obj => obj.Status !== 12)?.length) * 100).toFixed(0)}%</p>
            <p>{(Number(sevenData?.data?.filter(e => e.Status === 1).length) / Number((sevenData?.data).filter(obj => obj.Status !== 12)?.length) * 100).toFixed(0)}%</p>
            <p>{(Number(yesterdayData?.data?.filter(e => e.Status === 1).length) / Number((yesterdayData?.data).filter(obj => obj.Status !== 12)?.length) * 100).toFixed(0)}%</p>
            <p>{(Number(todaysData?.data?.filter(e => e.Status === 1).length) / Number((todaysData?.data).filter(obj => obj.Status !== 12)?.length) * 100).toFixed(0)}%</p>
          </div>}
        </div> : null}

        {secondSpinner || thirdSpinner || fourthSpinner || fifthSpinner || sixthSpinner ? <Spinner style={{ width: '36px' }} color='white' /> : null}
        {message && <p style={{ color: "white" }}>{message}</p>}
      </div>
    </div>
  </div>
}