import React from "react";
import "./index.css";
import PropTypes from "prop-types";

const Spinner = (props) => {
    const propColor = props.color ?? "#00A3FF";
    const styles = {};

    switch (props.align) {
        case "left": break;
        case "center": {
            styles["margin"] = "0 auto";
            styles["maxWidth"] = "100%";
            styles["width"] = "100%";
            break;
        }
        case "right": {
            styles["left"] = "auto";
        }
        default: break;
    }

    return <svg className="customComponents__spinner" viewBox="0 0 50 50" style={{...styles, ...props.style}}>
        <circle className="customComponents__spinner__path" style={{stroke: propColor}} cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
    </svg>
};

Spinner.propTypes = {
    color: PropTypes.string,
    align: PropTypes.oneOf(["left", "center", "right"]),
}

export default Spinner;